export function slugify(str) {
	const cleaned = str.replace('&amp;', '');
	const slug = cleaned
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');

	return slug
};