<template>
	<div class="l-page" data-file="app.vye">
		<DevOnly>
			<ResponsiveWitness />
		</DevOnly>
		
		<GeoNotification />
		<Header />

		<Cookies />

		<div
			style="margin-block-end: auto;"
			:class="[
				`${getNuxtPageCssClass}`,
			]"
		>
			<NuxtPage />
		</div>

		<footer class="bg-corpo px-3 px-lg-5 pt-4 pt-lg-5 pb-3">
			<Footer />
  	</footer>

		<Toast />

		<template v-if="CDP_ID !== null && CDP_ID !== ''">
			<div :id="`d${CDP_ID}`" class="js-cdp-dom"></div>
			<div class="d365-mkt-config" style="display:none" :data-website-id="CDP_ID" data-hostname="044b49c938884e31b948f578f6be6285.svc.dynamics.com"></div>
		</template>

		<canvas
			id="favicon-canvas"
			ref="refCanvas"
			width="64px"
			height="64px"
		></canvas>
	</div>
</template>

<script setup>
	import { storeToRefs } from 'pinia';
	import { getDevice } from './utils/device.js';

	// **************
	// **************
	// hooks
	const config = useRuntimeConfig();
	const { CDP_ID, FACEBOOK_ID } = config.public || {};

	const { locale } = useI18n();

	const faviconStore = useFavicon();
	const refFaviconStore = storeToRefs(faviconStore);

	// --------- data
	const getNuxtPageCssClass = computed(() => {
		if (locale.value === 'zh-hans') {
			return 'mandarin-text';
		}

		return '';
	});

	// handle global
	const globalFetchUrl = '/api/global';
	const { data: globalData } = await useNuxtData(globalFetchUrl);
	await useFetch(globalFetchUrl, {
		key: globalFetchUrl,
		default: () => {
			return globalData.value;
		}
	});

	// ---------- canvas loader
	const refCanvas = ref(null);
	const refCanvasInterval = ref(null);
	const refCanvasAngle = ref(0);
	const refreshRate = ref(25);
	const refIsSafari = ref(false);

	function updateCanvas() {
		if (process.client) {
			const step = 2;

			if (refCanvasAngle.value % step === 0) {
				const ctx = refCanvas?.value?.getContext('2d');

				if (ctx) {
					const d = refCanvas.value.width;
	
					ctx.clearRect(0, 0, d, d);
					ctx.save();
						ctx.translate(d / 2, d / 2);
						ctx.rotate((refCanvasAngle.value * Math.PI) / 180);
						ctx.translate((d / 2) * -1, (d / 2) * -1);
						drawCanvas();
					ctx.restore();
	
					const faviconElement = document.querySelector('link[rel*="icon"]');
					if (faviconElement) {
						faviconElement.href = refCanvas.value.toDataURL('image/png');
					}
				}
			}

			if (refCanvasAngle.value === 360) {
				refCanvasAngle.value = 0;
			} else {
				refCanvasAngle.value += 4;
			}
		}
	}

	function drawCanvas() {
		const ctx = refCanvas.value.getContext('2d');
		const d = refCanvas.value.width;
		let opacity = 0;


		ctx.clearRect(0, 0, d, d);
		ctx.translate(d / 2, d / 2);
		ctx.rotate(Math.PI * 360 / 360);

		ctx.lineWidth = Math.ceil(d / 50);
		ctx.lineCap = 'square';

		const colorFill = refFaviconStore.getTheme.value === 'dark' ?
			'white' :
			'black';

		for(let i = 0; i <= 360; i++) {
			ctx.save();
			const colorStroke = refFaviconStore.getTheme.value === 'dark' ?
				'rgba(255, 255, 255,' + opacity.toFixed(2) +')' :
				'rgba(40, 80, 160,' + opacity.toFixed(2) +')';

			ctx.rotate((Math.PI * i / 180));

			ctx.beginPath();
				ctx.moveTo(0, 0);
				opacity = (360 - (i * 0.95)) / 360;
				ctx.strokeStyle = colorStroke;
				ctx.lineTo(0, d + 30);
				ctx.stroke();
			ctx.closePath();

			ctx.restore();
		}

		ctx.globalCompositeOperation ='source-out';
		ctx.beginPath();
			ctx.arc(0, 0, d / 2, 2 * Math.PI, false);
			ctx.fillStyle = colorFill;
			ctx.fill();
			ctx.globalCompositeOperation ='destination-out';

		ctx.beginPath();
			ctx.arc(0, 0, (d / 2) * .9, 2 * Math.PI, false);
			ctx.fill();

		ctx.translate(0, 0);
	}

	const startDrawingInterval = () => {
		refCanvasAngle.value = 0;
		refCanvasInterval.value = setInterval(updateCanvas, refreshRate.value);
	}

	const clearDrawingInterval = () => {
		clearInterval(refCanvasInterval.value);

		const faviconElement = document.querySelector('link[rel*="icon"]');
		if (faviconElement) {
			faviconElement.href = '/favicon.ico';
		}
	}

	const isSafariBrowser = () => {
		if (process.client) {
			return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
		}

		return false;
	}

	/*
	** Lifecycle
	*/
	onMounted(() => {
		if (process.client) {
			// if (!window?.dataLayer && GTM_KEY !== null && GTM_KEY !== '') {
			// 	window.dataLayer = window.dataLayer || [];
			// }

			// FACEBOOK
			if (FACEBOOK_ID !== null && FACEBOOK_ID !== '') {
				!function(f,b,e,v,n,t,s)
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', FACEBOOK_ID);
				fbq('track', 'PageView');
			}
		}

		// set the favicon theme
		if (process.client) {
			refIsSafari.value = isSafariBrowser();

			const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
			const isDark = mediaQuery.matches;
			if (!isDark) {
				faviconStore.setTheme('light');
			}
		}
	});

	// watcher
	watch(refFaviconStore.isActive, () => {
		if (refFaviconStore.isActive.value && !refIsSafari.value) {
			startDrawingInterval();
		} else {
			clearDrawingInterval();
		}
	}, {
		deep: true
	});

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- app ---');
</script>

<style lang="scss">
	@import "@/assets/scss/style.scss";
	.mandarin-text {
		h1, h2, h3, h4, h5, h6 {
			letter-spacing: 1.45px;
			line-height: 1.45;
		}

		p, li, a {
			letter-spacing: 1.35px;
			line-height: 1.55;
		}

		// xl +
		@include mediaq('>lg') {
			p, li, a {
				letter-spacing: 1.85px;
			}
		}
	}
</style>

<style lang="scss" scoped>
.l-page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

footer {
	margin-block-start: auto;
}

.js-cdp-dom {
	display: none;
}

#favicon-canvas {
	position: absolute;
	top: -100vh;
	left: -100vw;
	width: 64px;
	height: 64px;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

.page-enter-active,
.page-leave-active {
	transition: all var(--ease-in) .18s;
}
.page-enter-from,
.page-leave-to {
	opacity: 0;
}
</style>