<template>
	<div
		:class="[
			SITE_NAME == 'lalprobiome'? 'container' : '',
			getComponentCssClass
		]"
	>
		<div class="row" ref="pageFooter">
			<!-- NOT lalprobiome -->
			<div v-if="SITE_NAME != 'lalprobiome'" class="col-12 col-lg-3 col-xl-2 pt-2 lallemand-section text-center text-lg-start">
				<LogoLallemand />
				<p class="mt-3">
					{{ $t('global_leader') }}
				</p>
			</div>

			<!-- ONLY lalprobiome -->
			<div  v-if="SITE_NAME == 'lalprobiome'" class="col-12 col-md-6 pt-2 lallemand-section text-center text-lg-start">
				<div class="mb-3 mb-lg-5 logo-lalprobiome">
					<LogoLalprobiome />
				</div>
				<div class="mb-3 mb-lg-0">
					<a href="https://lallemandanimalnutrition.com/" target="_blank">
						<LogoLan />
					</a>
				</div>
			</div>

			<!-- nav - NOT lalprobiome -->
			<div  v-if="SITE_NAME != 'lalprobiome'" class="col-12 col-lg-9 col-xl-9 offset-xl-1">
				<!-- accordions -->
				<div class="accordion accordion-small" id="accordion-footer">
					<div v-for="(item, index) in items" :key="item" :index="index" class="accordion-item">
						<div class="accordion-header" :id="'heading-footer-' + index">
							<button
								:id="'area-footer-' + index" 
								class="accordion-button cursor-pointer ps-0 d-lg-none"
								:class="item.isOpen ? '' : 'collapsed'" 
								type="button" 
								data-bs-toggle="collapse"
								:data-bs-target="'#footer-' + index" 
								:aria-expanded="item.isOpen" 
								:aria-controls="'footer-' + index"
							>
								<span v-html="item.title"></span>

								<span class="d-lg-none mobile-arrow">
									<ChevronSmall />
								</span>
							</button>

							<button class="accordion-button ps-0 d-none d-lg-block" type="button">
								<NuxtLink
									v-if="item.link_target === '_self' && item.link_href !== ''"
									:to="$url(item.link_href)"
								>
									<span v-html="item.title"></span>
									<span v-if="item.link_href != '#_'" class="ms-2 icon-arrow icon-desktop"
										:class="(item.isOpen ) ? 'show' : ''">
										<ArrowIcon />
									</span>
								</NuxtLink>

								<NuxtLink
									v-else-if="item.link_target !== '_self'"
									:to="$url(item.link_href)"
								>
									<span v-html="item.title"></span>
									<span v-if="item.link_href != '#_'" class="ms-2 icon-arrow icon-desktop"
										:class="(item.isOpen ) ? 'show' : ''">
										<ArrowIcon />
									</span>
								</NuxtLink>

								<div v-else>
									<span v-html="item.title"></span>
								</div>
							</button>
						</div>

						<div :id="'footer-' + index" class="accordion-collapse collapse" :aria-labelledby="'heading-footer' + index">
							<div class="accordion-body pt-0 pb-3 pb-lg-0 pt-lg-3 ps-0">
								<ul class="nav flex-column">
									<li class="nav-item mb-2">
										<NuxtLink
											v-if="item.link_target === '_self' && item.link_href !== '' && isMobile()"
											:to="$url(item.link_href)"
											class="nav-link p-0 small-font"
										>
											<span v-html="item.title"></span>
										</NuxtLink>
									</li>

									<li v-for="subItem in item.child" :key="subItem" class="nav-item mb-2">
										<a
											v-if="subItem.link_target == '_blank'" 
											:href="subItem.link_href"
											class="nav-link p-0 small-font"
											target="_blank"
										>
											{{ subItem.title }}
										</a>

										<NuxtLink v-else :to="$url(subItem.link_href)" class="nav-link p-0 small-font">
											<span>{{ subItem.title }}</span>
										</NuxtLink>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<!-- socials -->
					<div v-if="socialsMenu?.networks?.length > 0" class="accordion-item">
						<div class="accordion-header" id="socials-menu">
							<button
								v-if="isMobile()"
								:id="'area-social'"
								class="accordion-button ps-0"
								:class="'collapsed'"
								type="button"
								data-bs-toggle="collapse"
								:data-bs-target="`#socials-menu-row`"
								:aria-controls="`socials-menu-row`"
								:aria-expanded="true"
							>
								{{ socialsMenu?.title }}
								<span class="d-block d-lg-none mobile-arrow">
									<ChevronSmall />
								</span>
							</button>

							<button
								v-else
								:id="'area-social'"
								class="accordion-button ps-0"
								:class="'collapsed'"
								type="button"
							>
								{{ socialsMenu?.title }}
								<span class="d-block d-lg-none mobile-arrow">
									<ChevronSmall />
								</span>
							</button>
						</div>

						<div id="socials-menu-row" class="accordion-collapse show" aria-labelledby="area-social">
							<div class="accordion-body pt-0 pb-3 pb-lg-0 pt-lg-3">
								<ul class="nav flex-column">
									<li v-for="(socialItem, index) in socialsMenu?.networks" :key="index" class="nav-item mb-2 menu-nowrap">
										<ClientOnly>
											<a :href="socialItem.cta_link" target="_blank"
												class="d-inline-block nav-link p-0 small-font svg-white-fill icon-sociaux"
												:id="`social-icon-${index}`" v-html="socialItem.icon" data-trk-social="@TODO">
											</a>
										</ClientOnly>

										<a :href="socialItem.cta_link" target="_blank" class="d-inline-block nav-link p-0 small-font ms-1"
											v-html="socialItem.cta_label"></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- quicklinks hr - NOT lalprobiome-->
			<div v-if="quickLinks?.length > 0 && SITE_NAME != 'lalprobiome'" class="col-12 d-none d-lg-block">
				<hr>
			</div>

			<!-- quicklinks - NOT lalprobiome -->
			<div
				v-if="quickLinks?.length > 0"
				v-for="(menuItem, index) in quickLinks"
				:key="index"
				:class="[
					index == 0 && SITE_NAME != 'lalprobiome' ? 'col-xl-2 mt-4 mt-lg-0' : '',
					SITE_NAME != 'lalprobiome'? 'col-12 col-lg-3' : 'col-12 col-md-6 pt-2',
					index > 0 && SITE_NAME != 'lalprobiome' ? 'offset-lg-1' : ''
				]"
			>
				<hr v-if="index > 0" class="d-block d-lg-none">

				<p class="fs-4 font-bebas-neue mb-2 mb-lg-4">
					<span
						v-for="index of menuItem?.title.length"
						:key="index"
						v-html="index !== menuItem?.title.length ? menuItem?.title[index - 1] + ' ' : menuItem?.title[index - 1]"
						:class="[
							index !== menuItem?.title.length ? '' : 'text-secondary',
						]"
					>
					</span>
				</p>

				<p class="small-font mt-0 mt-lg-4 mb-2 mb-lg-3" v-html="menuItem.text"></p>

				<a
					v-if="menuItem.cta_link_href && menuItem.cta_link_target === '_blank'"
					:href="menuItem.cta_link_href"
					target="_blank"
					class="small-font link-external"
				>
					{{ menuItem.cta_label }}
					<span class="rotate-315 svg-white-stroke">
						<ArrowIcon />
					</span>
				</a>

				<NuxtLink
					v-if="menuItem.cta_link_href && menuItem.cta_link_target === '_self'"
					:to="$url(menuItem.cta_link_href)"
					class="small-font"
				>
					<span v-html="menuItem.cta_label"></span>
				</NuxtLink>
			</div>

			<!-- hr -->
			<div class="col-12">
				<hr>
			</div>


			<!-- Footer Disclaimer -->
			<div v-if="disclaimer" class="col-12 text-center">
				<span class="extra-small-font" v-html="disclaimer"></span>
			</div>
			<div v-if="disclaimer" class="col-12">
				<hr>
			</div>


			<!-- bottom -->
			<div class="col-12 col-lg-3 mt-lg-0">
				<button
					v-if="getActiveMarketBoolean()"
					type="button"
					data-bs-toggle="modal"
					data-bs-target="#regionsModal"
				>
					<span class="language-switch extra-small-font cursor-pointer">
						<EarthIcon />

						<template v-if="getActiveMarketBoolean()">
							<span
								v-if="getCurrentLangSwitcherItem()?.name" 
								v-html="getCurrentLangSwitcherItem()?.name + ' | '" 
								class="ms-1"
							></span> {{ $t('locale_' + locale) }}
						</template>

						<template v-if="!getActiveMarketBoolean()">
							&nbsp;{{ $t('locale_' + locale) }}
						</template>
					</span>
				</button>

				<div
					v-if="!getActiveMarketBoolean()"
				>
					<span class="language-switch extra-small-font cursor-pointer">
						<template
							v-if="availableLanguages.length > 2"
						>
							<div class="position-relative langue-menu" 	@click="openLanguages()" >
								<span class="active-langue"><EarthIcon />&nbsp;{{ $t('locale_' + locale) }}</span>
								<ul>
									<li
									v-for="item in availableLanguages"
									:key="item.code"
									class="pb-1">
										<div class="text-body">
										<b><a
												v-html="`${item.native_name}`"
												:href="`/${item.code}`"
												class="text-body py-1 d-inline-block"
											></a></b>
										</div>
									</li>
								</ul>
							</div>
						</template>
						<template v-else>
							<EarthIcon />&nbsp;
							<template v-for="item in availableLanguages" >
								<template v-if="item.code != locale">
									<a
										v-html="`${item.native_name}`"
										:href="`/${item.code}`"
									></a>
								</template>
							</template>
						</template>

					</span>
				</div>

			</div>

			<!-- polices nav -->
			<div class="col-12 col-lg-6 text-lg-center mt-3 mt-lg-0 links-block">
				<template v-for="(menuItem, index) in bottomMenu">
					<NuxtLink v-if="menuItem.link_target == '_self'" :to="$url(menuItem.link_href)" class="extra-small-font me-3">
						<span v-html="menuItem.title"></span>
					</NuxtLink>

					<a v-if="menuItem.link_target == '_blank'" :href="menuItem.link_href" class="extra-small-font me-3"
						target="_blank">
						<span v-html="menuItem.title"></span>
					</a>
				</template>

				<span class="extra-small-font me-3 cursor-pointer" data-bs-toggle="modal" data-bs-target="#manageCookiesModal"
					data-bs-backdrop="static" aria-controls="#manageCookiesModal" @click="openCookieModal()">
					{{ $t('cookies_preferences') }}
				</span>
			</div>

			<!-- copyright -->
			<div class="col-12 col-lg-3 mt-lg-0">
				<p class="extra-small-font text-lg-end text-nowrap mb-1 mt-1">
					&copy; {{ currentYear() }} Lallemand Inc. {{ $t('all_right_reserved') }}
				</p>
			</div>
		</div>

		<!-- scroll to top button -->
		<div id="top-arrow" class="top-arrow" :class="scrollToTopisVisible ? '' : 'd-none'" @click="$scrollToTop()">
			<ArrowIcon />
		</div>

		<!-- Modal -->
		<Modal :isOpen="marketModal?.active">
			<template #header>
				<div class="row">
					<div class="col-11">
						<div class="h2 text-primary mb-0">{{ $t('choose_country_region') }}</div>
					</div>

					<div class="col-1">
						<div class="text-end">
							<button
								type="button"
								data-bs-dismiss="modal"
							>
								<CloseSmallIcon class="btn-close-modal cursor-pointer" />
							</button>
						</div>
					</div>
				</div>
			</template>

			<div class="mt-3 pt-3 border-top">
				<div class="row">
					<ul>
						<template v-if="getActiveMarketBoolean()" v-for="(regions, keyI, index) in markets">
							<li class="pb-4" v-if="keyI">
								<p class="text-primary mb-1 h3 text-region">{{ keyI.replace('-', ' ') }}</p>

								<div v-for="(market, keyJ) in regions" class="text-body">
									<div v-if="market.redirect_link" :key="keyJ">
										<a :href="market.redirect_link" class="text-body py-1 d-inline-block" target="_blank">
											<span v-html="market.name"></span>
											<span class="threefourth">
												<Arrow45Icon />
											</span>
										</a>
									</div>

									<div 
										v-else-if="(
											market?.available_languages?.length > 1 && 
											market?.available_languages?.length !== 
											market?.hidden_languages?.length
										)"
									>
										<template v-if="!hasOnlyOneVisibleLang(market)">
											<span v-html="market.name"></span>:
											<template v-for="(lang, index) of market?.available_languages" :key="lang">
												<template v-if="showLangugeFrontend(market, lang)">
													<span v-if="index">|</span>
													<br v-if="market.name.length > 18 && index == 0" />
													<a
														v-if="!getTranslatedUrl({
															language: lang,
															market: market.slug,
														})"
														:href="`/${lang}/${market.slug}`"
														class="text-body  py-1 d-inline-block"
														:class="market.name.length > 18 && index == 0 ? 'pe-2' : 'px-2'"
													>
														{{ getPrettyLocale(lang) }}
													</a>

													<a
														v-if="getTranslatedUrl({
															language: lang,
															market: market.slug,
														})"
														:href="
														getTranslatedUrl({
															language: lang,
															market: market.slug,
														})"
														class="text-body  py-1 d-inline-block"
														:class="market.name.length > 18 && index == 0 ? 'pe-2' : 'px-2'"
													>
														{{ getPrettyLocale(lang) }}
													</a>
												</template>
											</template>
										</template>

										<template v-if="hasOnlyOneVisibleLang(market)">
											<template v-for="(lang, index) of market?.available_languages" :key="lang">
												<template v-if="showLangugeFrontend(market, lang)">
													<a
														v-if="!getTranslatedUrl({
															language: lang,
															market: market.slug,
														})"
														v-html="`${market.name} (${getPrettyLocale(market?.available_languages[0])})`"
														:href="`/${market?.available_languages[0]}/${market.slug}`"
														class="text-body py-1 d-inline-block"
													></a>

													<a
														v-if="getTranslatedUrl({
															language: lang,
															market: market.slug,
														})"
														v-html="`${market.name} (${getPrettyLocale(market?.available_languages[0])})`"
														:href="getTranslatedUrl({
															language: lang,
															market: market.slug,
														})"
														class="text-body py-1 d-inline-block"
													></a>
												</template>
											</template>
										</template>
									</div>

									<div v-else-if="(
										market?.available_languages?.length === 1 && 
										market?.available_languages?.[0] !==
										market?.hidden_languages?.[0]
									)">
										<template v-for="(lang, index) of market?.available_languages" :key="lang">
											<template v-if="showLangugeFrontend(market, lang)">
												<a
													v-if="!getTranslatedUrl({
														language: lang,
														market: market.slug,
													})"
													v-html="`${market.name} (${getPrettyLocale(market?.available_languages[0])})`"
													:href="`/${market?.available_languages[0]}/${market.slug}`"
													class="text-body py-1 d-inline-block"
												></a>

												<a
													v-if="getTranslatedUrl({
														language: lang,
														market: market.slug,
													})"
													v-html="`${market.name} (${getPrettyLocale(market?.available_languages[0])})`"
													:href="getTranslatedUrl({
														language: lang,
														market: market.slug,
													})"
													class="text-body py-1 d-inline-block"
												></a>
											</template>
										</template>
									</div>

									<div v-else>
										<template v-if="market?.available_languages?.length === 1 && showLangugeFrontend(market)">
											<a
												v-html="`${market.name} (${getPrettyLocale(market?.available_languages[0])})`"
												:href="`/${market?.available_languages[0]}/${market.slug}`"
												class="text-body py-1 d-inline-block"
											></a>
										</template>
									</div>
								</div>
							</li>
						</template>

						<template
							v-if="!getActiveMarketBoolean()"
							v-for="item in availableLanguages"
							:key="item.code"
						>
							<li class="pb-4">
								<div class="text-body">
									<a
										v-html="`${item.native_name}`"
										:href="`/${item.code}`"
										class="text-body py-1 d-inline-block"
									></a>
								</div>
							</li>
						</template>
					</ul>
				</div>
			</div>
		</Modal>

		<div class="modal fade fast imageModal" id="image-content-modal" tabindex="-1" role="dialog"
			aria-labelledby="Image details" aria-hidden="true" ref="imageModal">
			<div class="backdrop" @click="closeImageInModal"></div>

			<div class="modal-dialog modal-lg modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header p-3">
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="{{ $t('close') }}"
							@click="closeImageInModal"
						></button>
					</div>

					<div class="modal-body d-flex align-items-center justify-content-center">
						<div>
							<img src="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { getEndpointUrl } from '#imports';

import LogoLallemand from './icons/logo-lallemand.vue';
import LogoLan from './icons/logo-lan-small.vue';
import ArrowIcon from './icons/arrow.vue';
import Arrow45Icon from './icons/arrow-45.vue';
import EarthIcon from './icons/earth.vue';
import CloseSmallIcon from './icons/close-small.vue';

const config = useRuntimeConfig();
const SITE_NAME = config.public.SITE_NAME || '';
const ACTIVE_MARKET = config.public.ACTIVE_MARKET || '';

const route = useRoute();
const { locale } = useI18n();
const { $url, $scrollToTop } = useNuxtApp();

const marketModal = useMarketModal();
const cookieModal = useCookieModal();

const pageStore = usePage();
const { page: currentPage, pageRedirect } = storeToRefs(pageStore);


// ***************
// ***************
// METHODS
const getActiveMarketBoolean = () => {
	if (ACTIVE_MARKET === 'false') {
		return false
	}

	return true;
}

const getCurrentMarketSlug = () => {
	//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
	// FIND WHY AND FIX IT (maybe  because it's in app.vue and not in <NuxtPage /> ?)
	if (getActiveMarketBoolean()) {
		const params = route?.params;
		const slugs = params?.market || 'europe';

		if (slugs.includes('robot') || slugs.includes('robots')) {
			return null
		}

		if (!slugs.includes('robot') && !slugs.includes('robots')) {
			return slugs;
		}
	}

	return '';
};

const getComponentCssClass = computed(() => {
	if (locale.value === 'zh-hans') {
		return 'mandarin-text';
	}

	return '';
});

// ***************
// ***************
// DATA FETCH

// global
const globalFetchUrl = '/api/global';
const { data: globalData } = useNuxtData(globalFetchUrl);
if (!globalData.value) {
	await useFetch(globalFetchUrl, {
		key: globalFetchUrl,
		default: () => {
			return globalData.value;
		}
	});
}

const { languages, markets } = globalData?.value || {};

// layout
const layoutFetchUrl = getEndpointUrl(
	'layout',
	getActiveMarketBoolean(),
	locale.value,
	'',
	getCurrentMarketSlug()
);
const { data: layoutData } = useNuxtData(layoutFetchUrl);
if (!layoutData.value) {
	await useFetch(layoutFetchUrl, {
		key: layoutFetchUrl,
		default: () => {
			return layoutData.value;
		}
	});
}

const getCurrentLangSwitcherItem = () => {
	let out = '';

	if (getActiveMarketBoolean()) {
		if (Object.keys(markets || {})?.length > 0) {
			Object.keys(markets).forEach(function (k, i) {
				const market = markets[k];
				const match = market.find((m) => {
					return m.slug === getCurrentMarketSlug();
				});
	
				if (match) {
					out = match;
				}
			})
		}
	}

	return out;
}

// reactive states
const menu = ref(layoutData?.value?.footer_menu || []);
const socialsMenu = ref(layoutData?.value?.footer_menu_social || {});
const quickLinks = ref(layoutData?.value?.footer_quick_links || []);
const bottomMenu = ref(layoutData?.value?.footer_policies || []);
const disclaimer = ref(layoutData?.value?.footer_disclaimer || '');


const getFrontendAvailableLanguages = () => {
	const sourceArray = globalData?.value?.languages || [];

	return sourceArray.filter((el) => {
		const hiddenKeyExist = "is_hidden" in el;

		if (!hiddenKeyExist) { return true }

		if (el.is_hidden) { return false }

		return el;
	})
}
const availableLanguages = ref(getFrontendAvailableLanguages());
// const availableLanguages = ref(globalData?.value?.languages || [])

const restructureMenu = (data) => {
	const originalMenu = data || [];
	const out = originalMenu.map((item, index) => {
		return {
			...item,
			isOpen: item.isOpen ? item.isOpen : false,
		}
	});

	return out;
}

const items = ref(restructureMenu(layoutData?.value?.footer_menu || []));
const scrollToTopisVisible = ref(true);

// ***************
// ***************
// METHODS
const currentYear = () => {
	const date = new Date();
	return date.getFullYear();
}

const isMobile = () => {
	if (process.client) {
		if (window.innerWidth <= 991) {
			return true
		}
	}

	return false
};

const openLanguages = () => {
	if (isMobile()) {
		let langueMenu = document.querySelector('.langue-menu');
	  langueMenu.classList.toggle('active-langue');
	}
}

const getPrettyLocale = (lang) => {
	let niceName = lang;
	if (typeof languages === 'array') {
		niceName = languages.map((item, index) => {
			if (item.code == lang) {
				return item.native_name;
			}
		});
	}
	if (typeof languages === 'object') {
		Object.keys(languages).forEach(function (key, index) {
			if (languages[key].code == lang) {
				niceName = languages[key].native_name;
			}
		})
	}
	return niceName;
}

function closeImageInModal() {
	let modal = document.querySelector('#image-content-modal');
	modal.classList.remove('show');
	modal.setAttribute('aria-hidden', 'true');
}

const showLangugeFrontend = (market, currentLang) => {
	const isHidden = market?.hidden_languages?.includes(currentLang);

	if (!currentLang &&  market?.hidden_languages?.length > 0) {
		return false;
	}

	return !isHidden;
}

const hasOnlyOneVisibleLang = (market, currentLang) => {
	const availableArrayLength = market?.available_languages?.length || 0;
	const hiddenArrayLength = market?.hidden_languages?.length || 0;

	
	if (availableArrayLength === hiddenArrayLength) {
		return false;
	}

	if (availableArrayLength - hiddenArrayLength === 1) {
		return true;
	}

	return false;
}

const getTranslatedUrl = ({ market, language  }) => {
	if (Object.keys(currentPage?.value?.links || {}.length > 0)) {
		const links = currentPage.value.links;

		// if has markets
		if (getActiveMarketBoolean()) {
			if (links?.[market]?.[language]) {
				// on LALPROBIOME - this url returns the market twice for some reason
				// this fixes this problem by replacing /${market}/${getCurrentMarketSlug()} by
				// /${market}

				if ( links[market][language].includes(`/${market}/${getCurrentMarketSlug()}`) ) {
					const marketFixedNewLink = links[market][language].replace(`/${market}/${getCurrentMarketSlug()}`, `/${market}`);

					return $url(marketFixedNewLink);
				}

				return $url(links[market][language]);
			}
		}

		// if has NO markets
		if (!getActiveMarketBoolean()) {
			if (links?.[language]) {
				return links[language];
			}
		}
	}

	return null;
}

// handle cookie
const openCookieModal = () => {
	cookieModal.setActive(true);
}

// watcher
watch(pageRedirect, async () => {
	if (pageRedirect.value?.redirect === true) {
		const layoutFetchUrl = getEndpointUrl(
			'layout', 
			getActiveMarketBoolean(),
			locale.value,
			'',
			pageRedirect.value.market
		);

		const { data: newLayoutData } = await useFetch(layoutFetchUrl, {
			key: layoutFetchUrl,
			default: () => {
				return layoutData.value;
			}
		});

		// update ref
		menu.value = newLayoutData?.value?.footer_menu || [];
		socialsMenu.value = newLayoutData?.value?.footer_menu_social || {};
		quickLinks.value = newLayoutData?.value?.footer_quick_links || [];
		bottomMenu.value = newLayoutData?.value?.footer_policies || [];
		disclaimer.value = newLayoutData?.value?.footer_disclaimer || '';

		items.value = restructureMenu(newLayoutData?.value?.footer_menu || []);
	}
}, {
	deep: true
})

// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log(' ');
// console.log('--- footer ---');
// console.log('globalData.value: ', globalData.value);
// console.log('layoutData.value: ', layoutData.value);
// console.log('route: ', route);
// console.log('currentPage: ', currentPage);
</script>

<style lang="scss" scoped>
	button {
		&[type] {
			margin: 0;
			padding: 0;
			background: transparent;
			border: none;
			color: var(--white);
		}
	}

	.modal {
		ul {
			margin-block: 0;
			margin-inline: 0;
			padding-block: 0;
			padding-inline: 0;
			list-style: none;
			columns: 1 auto;

			li {
				margin: 0;
				padding-block: 0;
				padding-inline: calc(var(--bs-gutter-x) * .5);

				.text-region {
					font-size: calc(1.35rem + 1.2vw);
				}

				p {
					font-size: 1.6rem;
					margin: 0;
				}

				a {
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		@include mediaq('>md') {
			ul {
				columns: 2 auto;

				li {
					break-inside: avoid-column;
				}
			}
		}

		@include mediaq('>lg') {
			ul {
				columns: 3 auto;

				li {
					.text-region {
						font-size: 2.25rem;
					}
				}
			}
		}
	}

	.accordion {
		@include mediaq('>lg') {
			.icon-desktop {
				svg {
					transform: translate3d(0, -3px, 0) !important;
				}
			}
		}
	}

	.extra-small-font { font-size: .75rem; }

	.mandarin-text {
		h1, h2, h3, h4, h5, h6 {
			letter-spacing: 1.45px;
			line-height: 1.45;
		}

		p, li, a, button span, button {
			letter-spacing: 1.35px;
			line-height: 1.55;
		}

		// xl +
		@include mediaq('>lg') {
			p, li, a {
				letter-spacing: 1.85px;
			}
		}
	}

	@media (max-width: 991px) {
		.nav-item {
			border-bottom: 1px solid var(--light-primary);
			padding-bottom: 8px;
			padding-top: 8px;
			font-weight: 700;
			margin: 0 20px;
			.dropdown-toggle::after {
				right: 0;
				width: 1rem;
			}
			.dropdown-menu {
				display: none;
			}
		}
	}

	// min width 1070px
	@media (min-width: 1170px){
		.nav-item {
			margin-right: 1rem;
		}
	}

	// min width 1070px
	@media (min-width: 1280px) {
		.nav-item {
			margin-right: 2rem;
		}
	}
</style>
