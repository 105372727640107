// @TODO - REFACTOR TO USE AN OBJECT INSTEAD
// @TODO - DRY
export const getEndpointUrl = (
  name = '',
  activeMarket = true,
  locale = 'en',
  pageName = '',
  marketSlug = '',
  search = false,
  username = '',
  password = '',
) => {
  // global
  if (name === 'global') {
    return '/api/global';
  }

  // layout
  if (name === 'layout') {
    if (activeMarket) {
      return `/api/layout?locale=${locale}&market=${marketSlug}`;
    }

    if (!activeMarket) {
      return `/api/layout?locale=${locale}`;
    }
  }

  // content
  if (name === 'content') {
    if (activeMarket) {
      return `/api/content?locale=${locale}&market=${marketSlug}&page=${pageName}${search ? `&s=${search}` : ''}`;
    }

    if (!activeMarket) {
      return `/api/content?locale=${locale}&page=${pageName}${search ? `&s=${search}` : ''}`;
    }
  }

  // login
  if (name === 'login') {
    if (activeMarket) {
      return `/api/login?username=${username}&p=${password}&locale=${locale}&market=${marketSlug}`;
    }

    if (!activeMarket) {
      return `/api/login?username=${username}&p=${password}&locale=${locale}`;
    }
  }

  // sign-up
  if (name === 'sign-up') {
    if (activeMarket) {
      return `/api/sign-up?locale=${locale}&market=${marketSlug}&page=${pageName}`;
    }

    if (!activeMarket) {
      return `/api/sign-up?locale=${locale}&page=${pageName}`;
    }
  }

  // job-posting
  if (name === 'job-posting') {
    if (activeMarket) {
      return `/api/job-posting?locale=${locale}&market=${marketSlug}&page=${pageName}`;
    }

    if (!activeMarket) {
      return `/api/job-posting?locale=${locale}&page=${pageName}`;
    }
  }

  // request-password
  if (name === 'request-password') {
    if (activeMarket) {
      return `/api/request-password?locale=${locale}&market=${marketSlug}&page=${pageName}`;
    }

    if (!activeMarket) {
      return `/api/request-password?locale=${locale}&page=${pageName}`;
    }
  }

  // reset-password
  if (name === 'reset-password') {
    if (activeMarket) {
      return `/api/reset-password?locale=${locale}&market=${marketSlug}&page=${pageName}`;
    }

    if (!activeMarket) {
      return `/api/reset-password?locale=${locale}&page=${pageName}`;
    }
  }

  // update-user
  if (name === 'update-user') {
    if (activeMarket) {
      return `/api/update-user?locale=${locale}&market=${marketSlug}&page=${pageName}`;
    }

    if (!activeMarket) {
      return `/api/update-user?locale=${locale}&page=${pageName}`;
    }
  }

  // retailer-map
  if (name === 'retailer-map') {
    if (activeMarket) {
      return `/api/retailer-map?locale=${locale}&market=${marketSlug}&page=${pageName}`;
    }

    if (!activeMarket) {
      return `/api/retailer-map?locale=${locale}&page=${pageName}`;
    }
  }

  if (name === 'retailer-map-update') {
    if (activeMarket) {
      return `/api/retailer-map-update?locale=${locale}&market=${marketSlug}&page=${pageName}`;
    }

    if (!activeMarket) {
      return `/api/retailer-map-update?locale=${locale}&page=${pageName}`;
    }
  }

  // validate-email
  if (name === 'validate-email') {
    if (activeMarket) {
      return `/api/validate-email?locale=${locale}&market=${marketSlug}`;
    }

    if (!activeMarket) {
      return `/api/validate-email?locale=${locale}`;
    }
  }
};


export const getBackendEndpoint = (
	{
		activeMarket = true,
		config = {},
		locale = 'en',
		marketSlug = '',
		name = '',
		pageName = '',
		password = '',
		search = false,
		siteEndpoint = '',
		siteUrl = '',
		type = 'ss',
		username = '',
	}
) => {
	//  /fr/wp-json/lal/v2/content/code-de-conduite-des-fournisseurs
	//  /api/content?locale=fr&page=contactez-nous

  // content
	let out = '';

  if (name === 'content') {
    if (activeMarket) {
      out = `${siteUrl}/${locale}${marketSlug}${siteEndpoint}/${name}/${pageName}${search ? `?s=${search}` : ''}`;
    }

    if (!activeMarket) {
      out = `${siteUrl}/${locale}${siteEndpoint}/${name}/${pageName}${search ? `?s=${search}` : ''}`;
    }
  }

	if (name === 'layout') {
		if (activeMarket) {
			out = `${siteUrl}/${locale}${marketSlug}${siteEndpoint}/${name}`;
    }

    if (!activeMarket) {
			out = `${siteUrl}/${locale}${siteEndpoint}/${name}`;
    }
  }

	// form
  if (name === 'form') {
    if (activeMarket) {
			out = `${siteUrl}/${locale}${marketSlug}${siteEndpoint}/${name}`;
    }

    if (!activeMarket) {
			out = `${siteUrl}/${locale}${siteEndpoint}/${name}`;
    }
  }

	return out;
};


