<template>
 <div class="position-relative search-form">
  <form
   class="inside-button"
   id="search-form"
   method="get"
   @submit.prevent="submitHeadSearch"
  >
   <input
    type="text"
    class="form-control mb-0 mt-1 my-lg-3"
    :placeholder="$t('search')"
    :aria-label="$t('search')"
    id="q"
    v-model="input"
    name="q"
   />
   <button class="btn btn-input" type="submit">
    <MagnifyIcon />
   </button>

   <button class="btn btn-input" type="button" v-if="input" @click="input = ''">
    <EraseIcon />
   </button>
  </form>
 </div>
</template>

<script setup>
import { getEndpointUrl } from '#imports';

	import EraseIcon from "./icons/erase.vue";
	import MagnifyIcon from "./icons/magnify.vue";

	const config = useRuntimeConfig();
	const SITE_NAME = config.public.SITE_NAME || '';
	const ACTIVE_MARKET = config.public.ACTIVE_MARKET || '';

	// props
	defineProps({
		market: String,
		suggestedPosts: Array
	});

	// state
	const input = ref("");
	const showSuggestions = ref(true);
	const suggested = ref([]);
	const post = ref({ post: [] });
	const pages = ref([]);
	const events = ref({ post: [] });
	const resources = ref({ post: [] });
	const products = ref({ post: [] });
	const localePath = useLocalePath();
	const route = useRoute();
	const { locale } = useI18n();
	const router = useRouter();

	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	}

	const getCurrentMarketSlug = () => {
		if (getActiveMarketBoolean()) {
			const params = route?.params;
			const slugs = params?.market || 'europe';
	
			if (slugs.includes('robot') || slugs.includes('robots')) {
				return null
			}
	
			if (!slugs.includes('robot') && !slugs.includes('robots')) {
				return slugs;
			}
		}

		return '';
	};

	const layoutFetchUrl = getEndpointUrl(
		'layout', 
		getActiveMarketBoolean(),
		locale.value,
		'',
		getCurrentMarketSlug()
	);
	
	const { data: layoutData } = await useNuxtData(layoutFetchUrl);
	if (!layoutData.value) {
		await useFetch(layoutFetchUrl, {
			key: layoutFetchUrl,
			default: () => {
				return layoutData.value;
			}
		});
	}

	const submitHeadSearch = (event) => {
		const slug = layoutData?.value?.search_page_slug;
		const marketSlug = () => {
			if (getActiveMarketBoolean()) {
				return '/' + getCurrentMarketSlug();
			}

			return getCurrentMarketSlug()
		}

		const url = localePath('index') + marketSlug() + '/' + slug + '/';
		const variable = encodeURIComponent(input.value);
		const query = { s: variable };

		const { search_page_slug } = layoutData?.value || {};
		let isSearchPage = false;

		// console.log(' ');
		// console.log(' ');
		// console.log(' ');
		// console.log('--- submitHeadSearch ---');
		// console.log('marketSlug(): ', marketSlug());
		// console.log('getActiveMarketBoolean(): ', getActiveMarketBoolean());
		// console.log('getCurrentMarketSlug(): ', getCurrentMarketSlug());
		// console.log('locale: ', locale);
		// console.log('url: ', url);
		// console.log('query: ', query);
		// console.log('layoutData.value: ', layoutData.value);
		// console.log('route.path: ', route.path);
		// console.log('search_page_slug: ', search_page_slug);
		// console.log('isSearchPage: ', isSearchPage);
		
		if (search_page_slug) {
			const currentPath = route?.path || null;
			if (currentPath) {
				const slugsArray = currentPath.split('/');
				isSearchPage = slugsArray.includes(search_page_slug);
			}
		}

		// console.log('isSearchPage: ', isSearchPage);
		
		if (!isSearchPage) {
			// window.location.href = url + "?s=" + variable;
			return navigateTo({
				path: url,
				query: {
					...query
				}
			})
		}

		if (isSearchPage) {
			// console.log('IS SEARCH PAGE!!: ', route);
			// let q = {
			// 	...query,
			// 	filter: route?.query?.filter,
			// 	page: route?.query?.page
			// }
			// console.log('q: ', q);

			// console.log('url + "?s=" + variable: ', url + "?s=" + variable);
			window.location.href = url + "?s=" + variable;
			// return navigateTo({
			// 	replace: true,
			// 	path: url,
			// 	query: {
			// 		...q
			// 	},
			// })
		}


		// if(route.params?.slugs?.[0] == 'search' && input.value != '') {
		// 	
		// }
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && input.value != '') {
			submitHeadSearch();
		}
	}

	// lifecycle
	if (route?.query?.s) {
		input.value = route.query.s;
	}
	onMounted(() => {
		window.addEventListener('keydown', handleKeyDown)
	});

	onUnmounted(() => {
		window.removeEventListener('keydown', handleKeyDown)
	});
</script>

<style lang="scss" scoped>
.search-form {
  .inside-button {
    position: inherit;
    z-index: 1040;
  }
  input::placeholder {
    color: var(--primary);
  }
}
</style>
