import revive_payload_client_4sVQNw7RlN from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/xampp/htdocs/stack/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/xampp/htdocs/stack/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "C:/xampp/htdocs/stack/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "C:/xampp/htdocs/stack/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import appCreated_Do6gbavjWT from "C:/xampp/htdocs/stack/plugins/appCreated.js";
import error_Z9R5b5Ynf8 from "C:/xampp/htdocs/stack/plugins/error.js";
import file_saver_DLSt6g85cZ from "C:/xampp/htdocs/stack/plugins/file-saver.js";
import jszip_f9ZeoeT7sz from "C:/xampp/htdocs/stack/plugins/jszip.js";
import responsive_C4SvJ9oTMN from "C:/xampp/htdocs/stack/plugins/responsive.js";
import scrollToTop_jnWzptp5jr from "C:/xampp/htdocs/stack/plugins/scrollToTop.js";
import url_TTR8gCdawc from "C:/xampp/htdocs/stack/plugins/url.js";
import useBootstrap_client_YvgZ1lMKs1 from "C:/xampp/htdocs/stack/plugins/useBootstrap.client.js";
import vueGoogleMaps_client_VUecr4COVR from "C:/xampp/htdocs/stack/plugins/vueGoogleMaps.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  appCreated_Do6gbavjWT,
  error_Z9R5b5Ynf8,
  file_saver_DLSt6g85cZ,
  jszip_f9ZeoeT7sz,
  responsive_C4SvJ9oTMN,
  scrollToTop_jnWzptp5jr,
  url_TTR8gCdawc,
  useBootstrap_client_YvgZ1lMKs1,
  vueGoogleMaps_client_VUecr4COVR
]