<template>
	<div
		v-if="isActive && userLocale"
	 	ref="root"
	 	class="px-3 px-lg-100 py-3 bg-black js-geolocation"
 	>
	 <div class="row">
		 <div class="col-12 col-md-7 col-lg-8 my-auto">
			 <div class="text-white">
				{{ translate('geo_market_notice') }}
				 
				 <span 
					 class="cursor-pointer" 
					 :data-bs-toggle="isMobile() ? 'modal' : 'modal'" 
					 :data-bs-target="isMobile() ? '#regionsModal' : '#regionsModal'"
					 :aria-controls="isMobile() ? '#regionsModal' : '#regionsModal'" 
					 v-html="translate('geo_market_link')"
				 ></span>
			 </div>
		 </div>

		 <div class="col-12 col-md-5 col-lg-4 text-lg-end">
			 <button 
				 type="button" 
				 class="btn btn-sm btn-outline-white mt-3 mt-lg-0 ms-0 me-3 ms-lg-3"
				 :data-bs-toggle="isMobile() ? 'modal' : 'modal'"
				 :data-bs-target="isMobile() ? '#regionsModal' : '#regionsModal'"
				 :aria-controls="isMobile() ? '#regionsModal' : '#regionsModal'"
				 v-html="translate('select_country_region')"
			 >
			 </button>

			 <button
				 v-if="allowCloseNotification"
				 type="button" 
				 class="button-close d-inline-block mt-3 mt-lg-0" 
				 @click="closeNotifications()" 
				 :aria-label="translate('close')"
			 >
				 <span class="svg-primary-stroke">
					 <CloseIcon />
				 </span>
			 </button>
		 </div>
	 </div>
 </div>
</template>

<script setup>
	import { storeToRefs } from 'pinia';
	import { useNavigatorLanguage } from '@vueuse/core'

	import CloseIcon from './icons/close.vue';

	const TRANSLATION = {
		de: {
			'geo_market_notice': 'Es scheint, dass Sie sich nicht in Ihrer Heimatregion aufhalten.',
			'geo_market_link': 'Wählen Sie ein anderes Land oder eine andere Region, um Inhalte zu sehen, die für Ihren Standort spezifisch sind.',
			'select_country_region': 'Auswahl Land/Region',
			'close': 'SchlieЯen',
		},
		en: {
			'geo_market_notice': 'It appears that you are not in your home market.',
			'geo_market_link': 'Choose another country or region to see content specific to your location.',
			'select_country_region': 'Select Country/Region',
			'close': 'Close',
		},
		es: {
			'geo_market_notice': 'Parece que no está en su mercado local.',
			'geo_market_link': 'Elija otro país o región para ver contenido específico de su localización.',
			'select_country_region': '"Seleccione País/Región',
			'close': 'Cerrar',
		},
		fr: {
			'geo_market_notice': 'Il semblerait que vous n\'êtes pas dans votre région.',
			'geo_market_link': 'Choisissez un autre pays ou une autre région pour voir le contenu spécifique à votre localisation.',
			'select_country_region': 'Sélectionner le pays/la région',
			'close': 'Fermer',
		},
		it: {
			'geo_market_notice': 'Sembra che tu non sia nel tuo mercato di origine.',
			'geo_market_link': 'Scegli un altro paese o regione per vedere contenuti specifici per la tua posizione.',
			'select_country_region': 'Seleziona Paese/Regione',
			'close': 'Chiudi',
		},
		ja: {
			'geo_market_notice': 'アクセス元の地域と異なっているようです。',
			'geo_market_link': 'あなたの地域に合わせた内容を表示するために、国または地域を選んでください。',
			'select_country_region': '国/地域を選択',
			'close': '閉じる',
		},
		pl: {
			'geo_market_notice': 'Wszystko wskazuje na to, że nie wybrałeś polskiego rynku.',
			'geo_market_link': 'Wybierz inny kraj lub region aby móc korzystać z polskiej strony.',
			'select_country_region': 'Wybierz kraj/region',
			'close': 'Zamknij',
		},
		'pt-br': {
			'geo_market_notice': 'Esta mensagem aparece quando você não está em seu país de origem.',
			'geo_market_link': 'Escolha outro país ou região para acessar conteúdo específico da sua região.',
			'select_country_region': 'Selecione o País/Região',
			'close': 'Fechar',
		},
		'pt-pt': {
			'geo_market_notice': 'Esta mensagem aparece quando você não está em seu país de origem.',
			'geo_market_link': '"Escolha outro país ou região para acessar conteúdo específico da sua região.',
			'select_country_region': 'Selecione o País/Região',
			'close': 'Fechar',
		},
		ru: {
			'geo_market_notice': 'Похоже, в Вашем регионе нет данной продукции.',
			'geo_market_link': 'Выберите другую страну или регион, чтобы увидеть контент, предназначенный для вашего местоположения.',
			'select_country_region': 'Выберите Страну/Регион',
			'close': 'Закрыть',
		},
		'zh-hans': {
			'geo_market_notice': '您当前所在国家或地区不是您所选择的主页市场。',
			'geo_market_link': '选择一个国家或地区，查看针对您所在地区的内容。',
			'select_country_region': '选择国家/地区',
			'close': '关闭',
		},
		zh: {
			'geo_market_notice': '您当前所在国家或地区不是您所选择的主页市场',
			'geo_market_link': '选择一个国家或地区，查看针对您所在地区的内容。',
			'select_country_region': '选择国家/地区',
			'close': '关闭',
		},
	}
	
	// **************
	// **************
	// config
	const config = useRuntimeConfig();
	const { ACTIVE_MARKET, SITE_NAME } = config.public || {};

	// **************
	// **************
	// hooks
	const marketModal = useMarketModal();
	const route = useRoute();

	const i18n = useI18n();

	const userLocale = ref(null);

	const pageStore = usePage();
	const { addKeyValue } = pageStore;
	const { pageRedirect } = storeToRefs(pageStore);

	const { language } = useNavigatorLanguage();

	// **************
	// **************
	// methods
	const clientIP = ref('');
	const clientLocation = ref('');
	const userClientMarket = ref(null);
	const allowCloseNotification = ref(false);

	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	};

	const getCurrentMarketSlug = () => {
		//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
		// FIND WHY AND FIX IT (maybe  because it's in app.vue and not in <NuxtPage /> ?)
		if (getActiveMarketBoolean()) {
			const params = route?.params;
			const slugs = params?.market || 'europe';

			if (slugs.includes('robot') || slugs.includes('robots')) {
				return null
			}
	
			if (!slugs.includes('robot') && !slugs.includes('robots')) {
				return slugs;
			}
		}

		return '';
	};

	async function text(url) {
		return await fetch(url).then(res => res.text());
	}

	const getGeolocation = async (parsedClientLocation) => {
		const globalFetchUrl = `/api/global?geolocation=${parsedClientLocation}`;
		const globalData = await $fetch(globalFetchUrl)
		// const { data: globalData } = useNuxtData(globalFetchUrl);
		// if (!globalData.value) {
		// 	await useFetch(globalFetchUrl, {
		// 		key: globalFetchUrl,
		// 		default: () => {
		// 			return globalData.value;
		// 		}
		// 	});
		// }

		// console.log(' ');
		// console.log(' ');
		// console.log(' ');
		// console.log('--- getGeolocation ---');
		// console.log('globalFetchUrl: ', globalFetchUrl);
		// console.log('globalData?.value: ', globalData?.value);
		// console.log('i18n: ', i18n);
		// console.log('i18nMessages: ', i18nMessages);

		if (globalData) {
			addKeyValue('user-global', globalData.value);
		}

		if (globalData?.geolocation_market_slug) {
			userClientMarket.value = globalData?.geolocation_market_slug
		}

		// if geo if successfull
		if (globalData?.geolocation_language) {
			const { geolocation_language } = globalData || false;

			if (geolocation_language) {
				userLocale.value = geolocation_language;
				return
			}
		}

		// using browser locale
		if (language.value?.length > 0) {
			const parsedBrowserLang = language.value.substring(0, language.value.indexOf('-'));
			if (parsedBrowserLang) {
				userLocale.value = parsedBrowserLang

				return
			}
		}

		// fallback
		userLocale.value = 'en';
	}

	// refs
	const root = ref(null);
	const isActive = ref(false);

	// **************
	// **************
	// methods
	const closeNotifications = () => {
		marketModal.setActive(false);
	}

	const openModal = () => {
		marketModal.setActive(true);
	}

	const isMobile = () => {
		if (process.client) {
			if (window.innerWidth <= 991) {
				return true
			}
		}

		return false
	};


	const translate = (key) => {
		if (userLocale.value) {
			if (TRANSLATION?.[userLocale.value]?.[key]) {
				return TRANSLATION[userLocale.value][key];
			}
		}
  	return TRANSLATION['en'][key];
	};

	// **************************
	// **************************
	// watch
	watch(userClientMarket, () => {
		// console.log(' ');
		// console.log(' ');
		// console.log(' ');
		// console.log('--- userClientMarket ---');
		// console.log('userClientMarket?.value: ', userClientMarket?.value);
		// console.log('getCurrentMarketSlug(): ', getCurrentMarketSlug());
		if (userClientMarket.value !== getCurrentMarketSlug() && ACTIVE_MARKET === 'true') {
			// console.log('1');
			isActive.value = true;
		}
	}, {
		deep: true
	});

	watch(pageRedirect, (newValue, oldValue) => {
		if (SITE_NAME === 'brewing') {
			if (newValue?.market === userClientMarket.value) {
				isActive.value = false;
			} else {
				isActive.value = true;
			}
		}
	}, {
		deep: true
	})

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- geoloc ---');
	// console.log('i18n: ', i18n);
	// console.log('language.value: ', language.value);
	// console.log('i18nMessages: ', i18nMessages);
	// console.log('clientIP.value: ', clientIP.value);
	// console.log('clientLocation.value: ', clientLocation.value);

	// **************************
	// **************************
	// lifecycle
	onMounted(() => {
		if (getActiveMarketBoolean()) {
			text('https://www.cloudflare.com/cdn-cgi/trace').then((data) => {
				let ipRegex = /ip=(\S+)/;
				let ipLocation = /loc=(\S+)/;
	
				const parsedClientIP = data.match(ipRegex)?.[1] || '';
				const parsedClientLocation = data.match(ipLocation)?.[1] || '';
				// const parsedClientLocation = 'ru';
		
				clientIP.value = parsedClientIP;
				clientLocation.value = parsedClientLocation;
	
				// console.log(' ');
				// console.log(' ');
				// console.log('parsedClientIP: ', parsedClientIP);
				// console.log('parsedClientLocation: ', parsedClientLocation);
	
				if (parsedClientLocation) {
					getGeolocation(parsedClientLocation);
				}
			});
		}
	});
</script>

<style lang="scss" scoped>
</style>