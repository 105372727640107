export default defineNuxtPlugin((nuxtApp) => {
	const { $config } = nuxtApp;
	const BACKEND_SITE_URL = $config?.public?.BACKEND_SITE_URL || '';

	return {
		provide: {
			// @TODO use env variable
			url: (url = null, substring = BACKEND_SITE_URL) => {
				if (typeof url === 'string') {
					return url.replace(substring, '');
				}

				return '';
			},
    }
  }
})