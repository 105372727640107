<template>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 220.7 110.8" style="enable-background:new 0 0 220.7 110.8;" xml:space="preserve">
<g>
	<path fill="#E40428" d="M218.8,42.3C218.8,64.8,170.1,83,110,83C50,83,1.3,64.8,1.3,42.3S50,1.6,110,1.6
		C170.1,1.6,218.8,19.8,218.8,42.3z"/>
	<g>
		<path  fill="#FFFFFF" d="M22,28.2h6.2l-2.9,20.7H33L32,55.5H18.2L22,28.2z"/>
		<path  fill="#FFFFFF" d="M44.6,28.2h7.8l4.7,27.3h-6.3l-0.9-5.1h-8.1l-2.2,5.1h-6.7L44.6,28.2z M49.1,44.2l-1-6.4
			c-0.2-1-0.3-2.1-0.3-3.1c-0.5,1.7-0.9,2.8-1.1,3.2l-2.5,6.3H49.1z"/>
		<path  fill="#FFFFFF" d="M61.9,28.2h6.2l-2.9,20.7h7.7L72,55.5H58.1L61.9,28.2z"/>
		<path  fill="#FFFFFF" d="M76.9,28.2h6.2l-2.9,20.7h7.7l-0.9,6.5H73L76.9,28.2z"/>
		<path  fill="#FFFFFF" d="M102.6,49.4l-0.9,6.1H88.4l3.8-27.3h13.4l-0.9,6.3h-7.2l-0.6,4.5h6.7l-0.8,5.9h-6.7l-0.6,4.5H102.6z"/>
		<path  fill="#FFFFFF" d="M111.2,28.2h6.9l1.5,10.6c0.2,1.3,0.3,2.8,0.5,4.3c0.3-1.4,0.8-2.7,1.3-4.1l4.2-10.7h6.8l0.4,27.3h-5.7l0-13
			c0-1.3,0-2.7,0.2-4.3c-0.4,1.7-1,3-1.5,4l-5.4,13.3h-3.8l-2.1-13.2c-0.2-1.2-0.4-2.6-0.5-4.1c-0.2,1.6-0.5,3.1-0.8,4.3l-3.3,13h-6
			L111.2,28.2z"/>
		<path  fill="#FFFFFF" d="M144.9,28.2h7.8l4.7,27.3h-6.3l-0.9-5.1h-8.1l-2.2,5.1h-6.7L144.9,28.2z M149.4,44.2l-1-6.4
			c-0.2-1-0.3-2.1-0.3-3.1c-0.5,1.7-0.9,2.8-1.1,3.2l-2.5,6.3H149.4z"/>
		<path  fill="#FFFFFF" d="M163.1,28.2h6.2c0,0,8,15.9,8.4,17.9c0-0.2,0-0.5,0-0.7c0-1.2,0.1-2.5,0.3-3.9l1.9-13.4h6.2l-3.8,27.3h-6.8
			l-6.4-13.9c-0.4-1.1-1-2.6-1.3-4.5c0,0.3,0,0.5,0,0.8c0,1.3-0.1,2.6-0.3,3.8l-1.8,13.8h-6.2L163.1,28.2z"/>
		<path  fill="#FFFFFF" d="M189.7,28.2h4c4.3,0,7.4,0.1,9.5,1.3c3.4,2,5.2,6,5.2,11.5c0,5.2-1.7,9.1-5,11.9c-3.1,2.6-7,2.6-12.2,2.6
			c-0.3,0-0.6,0-0.9,0h-4.5L189.7,28.2z M194.5,49.5c5,0,7.6-3,7.6-8.7c0-4.7-1.8-6.6-5.9-6.6H195L193,49.4
			C193.5,49.4,194,49.5,194.5,49.5z"/>
	</g>
</g>
<g>
	<path fill="#FFFFFF" d="M3.2,98.9h2.2l-1.6,8.2h3.9L7.4,109h-6L3.2,98.9z"/>
	<path fill="#FFFFFF" d="M11.9,106.4l-1.2,2.6H8.5l4.9-10.1h2.8l1.2,10.1h-2.3l-0.2-2.6H11.9z M14.8,104.7l-0.2-2.2c0-0.6-0.1-1.4-0.1-2h0
		c-0.3,0.6-0.5,1.4-0.8,2l-1,2.2H14.8z"/>
	<path fill="#FFFFFF" d="M20.6,98.9h2.2l-1.6,8.2h3.9l-0.4,1.9h-6L20.6,98.9z"/>
	<path fill="#FFFFFF" d="M28.3,98.9h2.2l-1.6,8.2h3.9l-0.4,1.9h-6L28.3,98.9z"/>
	<path fill="#FFFFFF" d="M40.5,104.7h-3.6l-0.5,2.5h4l-0.3,1.8h-6.2l1.9-10.1h6l-0.4,1.8h-3.8l-0.4,2.1h3.6L40.5,104.7z"/>
	<path fill="#FFFFFF" d="M50.4,109l0.6-4.2c0.2-1.1,0.4-2.5,0.7-3.9h0c-0.6,1.3-1.2,2.7-1.7,3.8l-2.1,4.1h-1.7l-0.4-4.1c-0.1-1.1-0.2-2.5-0.2-3.9h0
		c-0.3,1.3-0.6,2.9-0.8,3.9l-1,4.2h-2l2.5-10.1h2.9l0.3,3.9c0.1,0.9,0.2,2.1,0.2,3.2h0.1c0.4-1.1,0.9-2.3,1.4-3.2l1.9-3.8h2.9
		L52.5,109H50.4z"/>
	<path fill="#FFFFFF" d="M57.4,106.4l-1.2,2.6h-2.3l4.9-10.1h2.8l1.2,10.1h-2.3l-0.2-2.6H57.4z M60.2,104.7l-0.2-2.2c0-0.6-0.1-1.4-0.1-2h0
		c-0.3,0.6-0.5,1.4-0.8,2l-1,2.2H60.2z"/>
	<path fill="#FFFFFF" d="M64.2,109l1.9-10.1h2.5l1.3,3.9c0.4,1.3,0.7,2.3,0.9,3.5h0c0-1,0.2-2.1,0.5-3.6l0.7-3.7h2L72.2,109H70l-1.4-4
		c-0.5-1.4-0.7-2.3-1-3.5h0c-0.1,1-0.3,2.4-0.6,4l-0.7,3.5H64.2z"/>
	<path fill="#FFFFFF" d="M76.4,99c0.9-0.1,1.8-0.2,2.8-0.2c1.6,0,2.8,0.4,3.7,1.1c0.8,0.7,1.3,1.7,1.3,3.2c0,2-0.8,3.6-2.1,4.6
		c-1.2,0.9-2.7,1.4-4.8,1.4c-1.2,0-2.3-0.1-2.8-0.2L76.4,99z M77,107.3c0.3,0,0.6,0,0.9,0c1.2,0,2.2-0.4,2.9-1.2
		c0.7-0.8,1.1-1.8,1.1-3.1c0-1.6-0.9-2.5-2.7-2.5c-0.4,0-0.7,0-0.9,0.1L77,107.3z"/>
	<path fill="#FFFFFF" d="M90.8,106.4l-1.2,2.6h-2.3l4.9-10.1H95l1.2,10.1H94l-0.2-2.6H90.8z M93.7,104.7l-0.2-2.2c0-0.6-0.1-1.4-0.1-2h0
		c-0.3,0.6-0.5,1.4-0.8,2l-1,2.2H93.7z"/>
	<path fill="#FFFFFF" d="M97.6,109l1.9-10.1h2.5l1.3,3.9c0.4,1.3,0.7,2.3,0.9,3.5h0c0-1,0.2-2.1,0.5-3.6l0.7-3.7h2l-1.9,10.1h-2.2l-1.4-4
		c-0.5-1.4-0.7-2.3-1-3.5h0c-0.1,1-0.3,2.4-0.6,4l-0.7,3.5H97.6z"/>
	<path fill="#FFFFFF" d="M111.9,98.9L110,109h-2.2l1.9-10.1H111.9z"/>
	<path fill="#FFFFFF" d="M120.8,109l0.6-4.2c0.2-1.1,0.4-2.5,0.7-3.9h0c-0.6,1.3-1.2,2.7-1.7,3.8l-2.1,4.1h-1.7l-0.4-4.1c-0.1-1.1-0.2-2.5-0.2-3.9
		h0c-0.3,1.3-0.6,2.9-0.8,3.9l-1,4.2h-2l2.5-10.1h2.9l0.3,3.9c0.1,0.9,0.2,2.1,0.2,3.2h0.1c0.4-1.1,0.9-2.3,1.4-3.2l1.9-3.8h2.9
		l-1.4,10.1H120.8z"/>
	<path fill="#FFFFFF" d="M127.7,106.4l-1.2,2.6h-2.3l4.9-10.1h2.8l1.2,10.1h-2.3l-0.2-2.6H127.7z M130.5,104.7l-0.2-2.2c0-0.6-0.1-1.4-0.1-2h0
		c-0.3,0.6-0.5,1.4-0.8,2l-1,2.2H130.5z"/>
	<path fill="#FFFFFF" d="M136.4,98.9h2.2l-1.6,8.2h3.9l-0.4,1.9h-6L136.4,98.9z"/>
	<path fill="#FFFFFF" d="M145.2,109l1.9-10.1h2.5l1.3,3.9c0.4,1.3,0.7,2.3,0.9,3.5h0c0-1,0.2-2.1,0.5-3.6l0.7-3.7h2l-1.9,10.1h-2.2l-1.4-4
		c-0.5-1.4-0.7-2.3-1-3.5h0c-0.1,1-0.3,2.4-0.6,4l-0.7,3.5H145.2z"/>
	<path fill="#FFFFFF" d="M159.5,98.9l-1.1,5.8c-0.1,0.3-0.1,0.8-0.1,1.2c0,0.8,0.5,1.5,1.5,1.5c1.1,0,1.9-0.7,2.2-2.6l1.1-5.8h2.2l-1.1,5.7
		c-0.6,3.1-1.9,4.5-4.7,4.5c-2.1,0-3.4-1.1-3.4-3.2c0-0.4,0.1-0.9,0.1-1.4l1.1-5.6H159.5z"/>
	<path fill="#FFFFFF" d="M169,100.8h-2.7l0.4-1.9h7.5l-0.4,1.9h-2.7l-1.6,8.2h-2.2L169,100.8z"/>
	<path fill="#FFFFFF" d="M175.7,99.1c0.7-0.2,1.7-0.3,2.7-0.3c1,0,2,0.1,2.7,0.6c0.7,0.4,1.1,1,1.1,2c0,1.5-1,2.4-2.3,2.9v0c0.6,0.3,0.9,0.9,1,1.8
		c0.1,1.1,0.2,2.5,0.4,2.9H179c-0.1-0.3-0.2-1.1-0.3-2.4c-0.1-1.2-0.5-1.6-1.4-1.6h-0.7l-0.8,4h-2.1L175.7,99.1z M177,103.4h0.9
		c1.2,0,2-0.7,2-1.7c0-0.8-0.7-1.2-1.5-1.2c-0.4,0-0.7,0-0.8,0.1L177,103.4z"/>
	<path fill="#FFFFFF" d="M186.9,98.9l-1.9,10.1h-2.2l1.9-10.1H186.9z"/>
	<path fill="#FFFFFF" d="M190.6,100.8H188l0.4-1.9h7.5l-0.4,1.9h-2.7l-1.6,8.2h-2.2L190.6,100.8z"/>
	<path fill="#FFFFFF" d="M199.5,98.9l-1.9,10.1h-2.2l1.9-10.1H199.5z"/>
	<path fill="#FFFFFF" d="M204.4,109.2c-2.6,0-4.1-1.9-4.1-4.2c0-1.8,0.7-3.6,1.9-4.8c0.9-0.9,2.2-1.4,3.7-1.4c2.7,0,4.1,1.8,4.1,4.2
		c0,1.8-0.6,3.6-1.8,4.8C207.2,108.6,205.9,109.2,204.4,109.2L204.4,109.2z M204.7,107.4c0.6,0,1.2-0.3,1.7-0.7
		c0.8-0.8,1.2-2.5,1.2-3.6c0-1.3-0.5-2.5-2-2.5c-0.7,0-1.3,0.3-1.7,0.8c-0.8,0.8-1.3,2.4-1.3,3.6
		C202.6,106.3,203.2,107.4,204.7,107.4L204.7,107.4z"/>
	<path fill="#FFFFFF" d="M210.7,109l1.9-10.1h2.5l1.3,3.9c0.4,1.3,0.7,2.3,0.9,3.5h0c0-1,0.2-2.1,0.5-3.6l0.7-3.7h2l-1.9,10.1h-2.2l-1.4-4
		c-0.4-1.4-0.7-2.3-1-3.5h0c-0.1,1-0.3,2.4-0.6,4l-0.7,3.5H210.7z"/>
</g>
</svg>
</template>