export const useFavicon = defineStore('favicon', {
  state: () => ({
    active: false,
		theme: 'dark',
  }),

  getters: {
    isActive: (state) => state.active,
    getTheme: (state) => state.theme,
  },

  actions: {
    setActive(newState) {
			if (newState === true || newState === false) {
				this.active = newState;
				return;
			}

			this.active = false;
    },

		setTheme(newState) {
			if (newState === 'dark' || newState === 'light') {
				this.theme = newState;
				// return;
			}

			// this.theme = 'dark';
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFavicon, import.meta.hot))
}
