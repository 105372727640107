export default defineNuxtPlugin((nuxtApp) => {
	const doLog = true;
	
	nuxtApp.hook('vue:error', (..._args) => {
		if (doLog) {
			console.log(' ');
			console.log(' ');
			console.log(' ');
			console.log('--- vue:error ---');
			if (process.client) {
				console.log(..._args);
			}
		}
	})
	nuxtApp.hook('app:error', (..._args) => {
		if (doLog) {
			console.log(' ');
			console.log(' ');
			console.log(' ');
			console.log('--- app:error ---', );
			if (process.client) {
				console.log(..._args);
			}
		}
	})
	nuxtApp.vueApp.config.errorHandler = (..._args) => {
		if (doLog) {
			console.log(' ');
			console.log(' ');
			console.log(' ');
			console.log('--- global error handler ---');
			if (process.client) {
				console.log(..._args);
			}
		}
	}
})