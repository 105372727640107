// methods
export const lockBody = () => {
	if (process.client) {
		const body = document.querySelector('body');
		body.style.overflow = "hidden";
		// body.style.paddingInlineEnd = "17px";
	}
}

export const unLockBody = () => {
	if (process.client) {
		const body = document.querySelector('body');
		body.style.overflow = "visible";
		// body.style.paddingInlineEnd = "0";
	}
}