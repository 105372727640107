/**
 * @typedef {Object} Breakpoint
 * @property {string} name - The name of the breakpoint
 * @property {string} value - The value of the breakpoint
 * @property {boolean} active - Whether or not the breakpoint is active
 */

/**
 * @typedef {Object.<string, Breakpoint>} Breakpoints
 */

/**
 * @typedef {Object} Provide
 * @property {(htmlElement: HTMLElement) => void} initResponsive - Initializes responsive design
 * @property {() => string} getActiveBreakpoint - Returns the active breakpoint
 * @property {(bp: string, asNumber?: boolean) => number | string | false} getBreakpointValue - Returns the value of a breakpoint
 * @property {(bp: string) => boolean} isBreakpointActive - Returns whether or not a breakpoint is active
 * @property {(bp: string) => boolean} isBreakpointNotActive - Returns whether or not a breakpoint is not active
 */

/**
 * Defines a Nuxt plugin that provides responsive design functionality.
 *
 * @param {Object} nuxtApp - The Nuxt app object.
 * @returns {Object} An object containing the `provide` object.
 */

export default defineNuxtPlugin((nuxtApp) => {
	const element = ref(null);
	const breakpoints = ref(false);

	/**
	 * Reads the breakpoints from the DOM.
	 *
	 * @returns {Breakpoints | false} The breakpoints or false if they could not be read.
	 */
	const readBreakpoints = () => {
		// if (process.client) {
		// 	if (window.getComputedStyle && 
		// 		(window.getComputedStyle(element.value, '::after').content !== '')) {
		// 		const data = window.getComputedStyle(element.value, '::after').content;
	
		// 		try {
		// 			breakpoints.value = JSON.parse(removeQuotes(data));
		// 		} catch (err) {
		// 			breakpoints.value = false;
		// 		}
		// 	} else {
		// 		breakpoints.value = false;
		// 	}
	
		// 	return breakpoints.value;
		// }
	}

	/**
	 * Gets the value of a breakpoint.
	 *
	 * @param {string} breakpoint - The name of the breakpoint to get.
	 * @param {boolean} [asNumber=true] - Whether or not to return the value as a number.
	 * @returns {number | string | false} The value of the breakpoint or false if it does not exist.
	 */
	const getBreakpointValue = (breakpoint, asNumber = true) => {
		// readBreakpoints();

		if (!breakpoints.value || !breakpoints.value.hasOwnProperty(breakpoint)) {
			return false;
		}

		return asNumber ?
			parseFloat(breakpoints.value[breakpoint].value) : 
			breakpoints.value[breakpoint].value;
	}

	/**
	 * Gets the name of the active breakpoint.
	 *
	 * @returns {string} The name of the active breakpoint.
	 */
	const getActiveBreakpoint = () => {
		// readBreakpoints();

		let largest = {name: false, value: 0};

		for (let breakpoint in breakpoints.value) {
			if (breakpoints.value.hasOwnProperty(breakpoint)) {
				let breakpointValue = parseFloat(breakpoints.value[breakpoint].value);
				if (breakpoints.value[breakpoint].active && (breakpointValue > largest.value)) {
					largest = {name: breakpoint, value: breakpointValue};
				}
			}
		}

		return largest.name;
	}

	/**
 * Checks if a given breakpoint is active.
 *
 * @param {string} breakpoint - The name of the breakpoint to check.
 * @returns {boolean} Whether or not the given breakpoint is active.
 */
	const isBreakpointActive = (breakpoint) => {
		// readBreakpoints();

		return breakpoints.value.hasOwnProperty(breakpoint) && breakpoints.value[breakpoint].active;
	}

	const removeQuotes = (string) => {
		if (typeof string === 'string' || string instanceof String) {
			string = string.replace(/[']/g, '"').replace(/\\|^[\s\S]{0,1}|[\s\S]$/g, '');
		}

		return string;
	}

	return {
		provide: {
			initResponsive: (htmlElement) => {
				element.value = htmlElement;
				readBreakpoints();
			},
			getActiveBreakpoint: () => {
				return getActiveBreakpoint();
			},
			getBreakpointValue: (bp, asNumber = true) => {
				return getBreakpointValue(bp, asNumber);
			},
			isBreakpointActive: (bp) => {
				return isBreakpointActive(bp);
			},
			isBreakpointNotActive: (bp) => {
				return !isBreakpointActive(bp);
			}
    }
  }
})