import validate from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/pages/runtime/validate.js";
import always_45run_45global from "C:/xampp/htdocs/stack/middleware/always-run.global.js";
import manifest_45route_45rule from "C:/xampp/htdocs/stack/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  always_45run_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-mw": () => import("C:/xampp/htdocs/stack/middleware/auth-mw.js")
}