import Dropdown from 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';
import Popover from 'bootstrap/js/dist/popover';
import Tooltip from 'bootstrap/js/dist/tooltip';
import Collapse from 'bootstrap/js/dist/collapse';

export default defineNuxtPlugin(nuxtApp => {

	const obj = {
		Collapse,
		Dropdown,
		Modal,
		Popover,
		Tooltip
	}
	if (import.meta.client) {
		nuxtApp.provide('bootstrap', obj);
	}
})