<template>
  <svg width="24" height="24" viewBox="0 0 24 24" id="arrow" title="arrow" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 19L21 12L14 5" :stroke="getColor()" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 12L21 12" :stroke="getColor()" stroke-width="1.5" stroke-linecap="round"/>
	</svg>
</template>

<script setup>
	const props = defineProps({
		color: String
	})

	const getColor = () => props.color || '#323E48';
</script>
