<template>
	<div class="component">
		<div class="component__container toast-container position-relative">

			<TransitionGroup
				name="fade"
				tag="div"
			>
				<div
					v-for="toast in toasts"
					:key="toast.id"
					class="toast align-items-center show text-bg-success" 
					role="alert" 
					aria-live="assertive" 
					aria-atomic="true"
				>
					<div class="d-flex">
						<div
							v-if="toast?.text"
							v-html="toast.text"
							class="toast-body"
						>
						</div>
						<button
							type="button" 
							class="btn-close btn-close-white me-2 m-auto" 
							aria-label="Close"
							@click="removeToastByID(toast.id)"
						></button>
					</div>
				</div>
			</TransitionGroup>

		</div>
	</div>
</template>

<script setup>
	import { storeToRefs } from 'pinia';

	const { locale, t } = useI18n();
	const route = useRoute();
	const router = useRouter();

	const toastStore = useToast();
	const { addToast, removeToast } = toastStore;

	// props
	const props = defineProps({
		data: Object,
	});

	// computed
	const DELAY = 5000;

	// reactive data
	const { toasts } = storeToRefs(toastStore);
	const timers = ref({});

	// methods
	const getToastDelay = (t) => {
		if (t?.delay) {
			return parseInt(t.delay)
		}

		return DELAY;
	}

	const removeToastByID = (id) => {
		removeToast(id);

		// if the toast has a timeout, clear it
		if (timers.value[id]) {
			clearTimeout(timers.value[id]);
			delete timers.value[id]
		}

		return navigateTo({
			path: route.path,
			replace: true,
		});
	}

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- Toast ---')
	// console.log('toasts.value: ', toasts.value);
	// console.log('addToast: ', addToast);
	// console.log('removeToast: ', removeToast);
	// console.log('router: ', router);

	watch(toasts, (currentValue, oldValue) => {
		if (currentValue?.length > 0 && process.client) {
			currentValue.forEach((t) => {
				const id = t.id;

				// check if toast already has a timeout running
				if (!timers.value?.[id]) {
					// add timeout
					timers.value[id] = setTimeout(() => {
						removeToastByID(id);
					}, getToastDelay(t))
				}
			})
		}
	}, {
		deep: true // watch nested data
	});


	// lifecyle
	onUnmounted(() => {
		if (currentValue?.length > 0 && process.client) {
			currentValue.forEach((t) => {
				const id = t.id;
				removeToast(id);

				// if the toast has a timeout, clear it
				if (timers.value[id]) {
					clearTimeout(timers.value[id]);
					delete timers.value[id]
				}
			})
		}
	})
	
</script>

<style lang="scss" scoped>
	.component {
		position: fixed;
		inset: 0;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		pointer-events: none;
		z-index: 2000;

		&__container {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 15px;

			> div {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
			}
		}

		.toast {
			margin-block: 0 10px;
		}
	}

	/* 1. declare transition */
	.fade-move,
	.fade-enter-active,
	.fade-leave-active {
		transition: all var(--ease-in) .25s;
	}

	/* 2. declare enter from and leave to state */
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		transform: translate3d(0, -30px, 0);
	}

	/* 3. ensure leaving items are taken out of layout flow so that moving
				animations can be calculated correctly. */
	.fade-leave-active {
		position: absolute;
	}
</style>