<template>
	<div
		:class="['component', getRootCssClass]"
	>
		<button
			type="button"
			data-bs-dismiss="offcanvas"
			data-bs-target="#offcanvasCookies"
			aria-controls="offcanvasCookies"
			ref="generalModalButton"
			class="hidden-button"
		></button>
	
		<div
			v-show="generalModalIsActive"
			id="offcanvasCookies"
			class="offcanvas offcanvas-bottom" 
			:class="[`${generalModalIsActive ? 'show' : ''}`]"
			tabindex="-1"
			aria-labelledby="offcanvasCookiesLabel"
			:aria-modal="[`${generalModalIsActive ? 'true' : 'false'}`]"
			:role="[`${generalModalIsActive ? 'dialog' : ''}`]"
			data-bs-scroll="true"
			data-bs-backdrop="false"
		>
			<div class="offcanvas-body text-left px-0">
				<div class="px-4 pt-2">
					<p class="offcanvas-title h3 lh-1 mb-3" id="offcanvasCookiesLabel" v-html="$t('website_cookies')"></p>
	
					<p>{{ $t('cookies_acceptance') }}</p>
	
					<p v-if="bottomMenu?.[0]?.link_href">
						<NuxtLink
							v-if="bottomMenu[0]?.link_target !== '_blank'"
							v-html="bottomMenu[0]?.title"
							:href="bottomMenu[0].link_href" 
							:title="bottomMenu[0]?.title"
						></NuxtLink>
						<a
							v-if="bottomMenu[0]?.link_target === '_blank'"
							v-html="bottomMenu[0]?.title"
							:href="bottomMenu[0].link_href" 
							:title="bottomMenu[0]?.title"
							target="_blank"
						></a>
					</p>
	
					<div
						class="btn btn-primary d-block mb-4 py-3 cursor-pointer" 
						data-bs-dismiss="offcanvas"
						data-bs-target="#offcanvasCookies"
						aria-controls="offcanvasCookies"
						@click="acceptAll()"
					>
						{{$t('cookies_accept_all') }}
					</div>
	
					<div
						class="btn btn-outline-primary d-block mb-3 py-2 cursor-pointer"
						data-bs-dismiss="offcanvas"
						data-bs-target="#offcanvasCookies"
						aria-controls="offcanvasCookies"
						@click="acceptEssentials()"
					>
						{{ $t('only_essentials') }}
					</div>
	
					<div 
						class="btn btn-outline-primary d-block mb-3 py-2 cursor-pointer" data-bs-toggle="modal"
						data-bs-target="#manageCookiesModal" aria-controls="#manageCookiesModal" data-bs-dismiss="offcanvas"
						@click="openManagePreferences()"
					>
						{{ $t('manage_preferences') }}
					</div>
				</div>
			</div>
		</div>
	
		<div
			id="manageCookiesModal"
			class="modal fade"
			:class="[`${preferencesModalIsActive ? 'show' : ''}`]"
			data-bs-backdrop="static"
			:aria-modal="[`${preferencesModalIsActive ? 'true' : 'false'}`]"
			:role="[`${preferencesModalIsActive ? 'dialog' : ''}`]"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-body">
						<div class="row">
							<div class="col-10 col-lg-11">
								<div class="h4 text-primary mb-0">{{ $t('cookie_preferences') }}</div>
							</div>
	
							<div class="col-2 col-lg-1">
								<div class="text-end">
									<CloseSmallIcon class="btn-close-modal cursor-pointer" data-bs-dismiss="modal" />
								</div>
							</div>
						</div>
	
						<div class="mt-3 pt-3 border-top">
							<div v-for="(provider, key) in data" :key="key" class="row mb-3 border-bottom">
								<div class="col-10 col-md-11">
									<div class="h5 text-capitalize mb-1" v-html="$t(provider.name)"></div>
	
									<p class="small-font" v-html="$t(provider.description)"></p>
								</div>
	
								<div class="col-2 col-md-1 h5">
									<div class="form-check form-switch">
										<input v-if="provider.mandatory" class="form-check-input" type="checkbox" role="switch" :id="key"
											v-model="accepted[key]" checked disabled>
										<input v-else class="form-check-input" type="checkbox" role="switch" :id="key" v-model="accepted[key]">
									</div>
								</div>
							</div>
	
							<div
								v-if="false"
								class="my-2 small-font"
								v-html="$t('more_info_privacy', { baselink: $i18n.locale + '/' + $route.params.market })"
								></div>
	
							<div class="row">
								<div class="col-12 col-lg-4 pt-2">
									<div
										class="btn btn btn-primary py-2 cursor-pointer" 
										@click="confirmSelection()" 
										data-bs-dismiss="modal"
									>
										{{$t('confirm_selection') }}
									</div>
								</div>
	
								<div class="col-6 col-lg-4 pt-2">
									<div 
										class="btn btn-outline-primary py-2 cursor-pointer"
										data-bs-dismiss="modal"
									>
										{{ $t('OK') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { datalayer } from '#imports';

import CloseSmallIcon from './icons/close-small.vue';

const config = useRuntimeConfig();
const { ACTIVE_MARKET, CDP_ID, GTM_KEY, NUXT_PUBLIC_GTAG_ID, SITE_NAME } = config.public || {};

const route = useRoute();
const cookieModal = useCookieModal();
const cookie = useCookie(SITE_NAME + '-cookie_analytical');
const { locale } = useI18n();

const scriptsLoaded = ref(false);

const datalayerStore = useDatalayer();
const refDatalayerStore = storeToRefs(datalayerStore);

const data = {
	functional: {
		name: 'cookie_functional',
		description: 'cookie_functional_explanation',
		mandatory: true
	},
	analytics: {
		name: 'cookie_analytical',
		description: 'cookie_analytical_explanation',
		mandatory: false
	}
}

const getActiveMarketBoolean = () => {
	if (ACTIVE_MARKET === 'false') {
		return false
	}

	return true;
};

const getCurrentMarketSlug = () => {
	//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
	// FIND WHY AND FIX IT (maybe  because it's in app.vue and not in <NuxtPage /> ?)
	if (getActiveMarketBoolean()) {
		const params = route?.params;
		const slugs = params?.market || 'europe';

		if (slugs.includes('robot') || slugs.includes('robots')) {
			return null
		}

		if (!slugs.includes('robot') && !slugs.includes('robots')) {
			return slugs;
		}
	}

	return '';
};

const layoutFetchUrl = getEndpointUrl(
	'layout', 
	getActiveMarketBoolean(),
	locale.value,
	'',
	getCurrentMarketSlug()
);

const { data: layoutData } = await useNuxtData(layoutFetchUrl);
if (!layoutData.value) {
	await useFetch(layoutFetchUrl, {
		key: layoutFetchUrl,
		default: () => {
			return layoutData.value;
		}
	});
}

const sixMonthsInSeconds = 6 * 30 * 24 * 60 * 60 // approximately 6 months

// refs
const generalModalIsActive = ref(false);
const preferencesModalIsActive = ref(cookieModal?.active);
const generalModalButton = ref(null);
const accepted = ref({});
const bottomMenu = ref(layoutData?.value?.footer_policies || []);

const getRootCssClass = computed(() => {
	if (locale.value === 'zh-hans') {
		return 'mandarin-text';
	}

	return '';
});

// ***************
// ***************
// METHODS
const generateCookieName = (str) => {
	return `${SITE_NAME}-${str}`;
}

const showGeneralModal = () => {
	generalModalIsActive.value = true;
}

const scriptsToLoad = () => {
	scriptsLoaded.value = true
	let out = [];

	// CDP
	if (CDP_ID) {
		out.push(
			{
				src: `https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007`,
				type: "text/javascript",
				async: true,
			}
		)
	}

	return out;
}

const acceptAll = () => {
	Object.keys(data).forEach(function (key, index) {
		const item = data[key];
		const name = generateCookieName(item.name);

		const currentCookie = useCookie(name, {...cookiesOptions});
		currentCookie.value = true;
	});

	generalModalIsActive.value = false;

	// GA4
	if (NUXT_PUBLIC_GTAG_ID) {
		const { grantConsent } = useGtag();
		grantConsent(NUXT_PUBLIC_GTAG_ID);
	}

	// GTM
	if (GTM_KEY) {
		const gtm = useGtm();
		const isEnabled = gtm.enabled();

		if (!isEnabled) {
			gtm.enable(true);
		}
	}

	// other tracking scripts
	useHead({
		script: scriptsToLoad()
	});

	sendDatalayer();
}

const acceptEssentials = () => {
	Object.keys(data).forEach(function (key, index) {
		const item = data[key];
		const name = generateCookieName(item.name);

		const currentCookie = useCookie(name, {...cookiesOptions});
		currentCookie.value = item.mandatory ? true : false;

		// GA4
		if (
			name === `${SITE_NAME}-cookie_analytical` &&
			NUXT_PUBLIC_GTAG_ID
		) {
			const { revokeConsent } = useGtag();
			revokeConsent(NUXT_PUBLIC_GTAG_ID);
	}
	});

	generalModalIsActive.value = false;
}

const confirmSelection = () => {
	Object.keys(data).forEach(function (key, index) {
		const item = data[key];
		const name = generateCookieName(item.name);
		const isMandatory = item.mandatory;

		const currentCookie = useCookie(name, {...cookiesOptions});
		if (!isMandatory) {
			currentCookie.value = accepted?.value?.[key] ? true : false;
		}

		if (isMandatory) {
			currentCookie.value = true;
		}

		// if cookie is true
		if (name === SITE_NAME + '-cookie_analytical' && currentCookie?.value) {
			// GA4
			if (NUXT_PUBLIC_GTAG_ID) {
				const { grantConsent } = useGtag();
				grantConsent(NUXT_PUBLIC_GTAG_ID);
			}

			// GTM
			if (GTM_KEY) {
				const gtm = useGtm();
				const isEnabled = gtm.enabled();

				if (!isEnabled) {
					gtm.enable(true);
				}
			}
			
			sendDatalayer();
		}

		// if cookie is false
		if (name === SITE_NAME + '-cookie_analytical' && !currentCookie.value) {
			// GA4
			if (NUXT_PUBLIC_GTAG_ID) {
				const { revokeConsent } = useGtag();
				revokeConsent(NUXT_PUBLIC_GTAG_ID);
			}
		}
	});
}

const formatData = (date) => {
	return new Date(date * 1000);
}

const addDays = (days) => {
	const currentDate = new Date();
	const result = currentDate.setDate(currentDate.getDate() + days);

	return result;
}

const cookiesOptions = {
	maxAge: sixMonthsInSeconds
}

const openManagePreferences = () => {
	generalModalButton.value.click();
	generalModalIsActive.value = false;

	nextTick(() => {
		cookieModal.setActive(true);
	});
}

// ***************
// ***************
// DATALAYER
const sendDatalayer = () => {
	// prepare base object used for the data layer
	let eventTypeData = {};
	let eventData = {};

	// type
	eventTypeData.event = `page_view`;
	// data
	eventData = {
		...refDatalayerStore?.datalayer?.value 
	}

	const mergeDatalayer = {
		data: {
			...eventTypeData,
			...eventData
		}
	}

	datalayer(mergeDatalayer);
}

// ***************
// ***************
// lifecycle
onMounted(() => {
	// if cookies dont exist
	if (cookie.value === undefined) {
		showGeneralModal();
	} else {
		Object.keys(data).forEach(function (key, index) {
			const item = data[key];
			const name = generateCookieName(item.name);
			const currentCookie = useCookie(name, {...cookiesOptions});

			// GA4
			if (
				name === `${SITE_NAME}-cookie_analytical` && 
				currentCookie?.value && 
				NUXT_PUBLIC_GTAG_ID
			) {
				const { grantConsent } = useGtag();
				grantConsent(NUXT_PUBLIC_GTAG_ID);
			}

			// GTM
			if (
				name === `${SITE_NAME}-cookie_analytical` && 
				currentCookie?.value && 
				GTM_KEY
			) {
				const gtm = useGtm();
				const isEnabled = gtm.enabled();

				if (!isEnabled) {
					gtm.enable(true);
				}
			}

			if (scriptsLoaded.value === false) {
				useHead({
					script: scriptsToLoad()
				})
			}
		});
	}
})
</script>

<style scoped lang="scss">
.mandarin-text {
	p, li {
		letter-spacing: 1.75px;
		line-height: 24px;
	}
}

.component {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 1055;
}

.hidden-button {
	visibility: hidden;
	height: 0.1px;
	width: 0.1px;
	position: absolute;
	top: -100vh;
	left: -100vw;
}
</style>