export const useDatalayer = defineStore('datalayer', {
	// state
  state: () => ({
    datalayer: {},
  }),

	// getters
  getters: {
    getDatalayer: (state) => state.datalayer,
  },

	// actions
  actions: {
    addDatalayer(obj) {
			this.datalayer = obj
    },

		removeDatalayer() {
			this.datalayer = {}
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDatalayer, import.meta.hot))
}
