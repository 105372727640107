export const useMarketModal = defineStore('marketModal', {
  state: () => ({
    active: false,
  }),

  getters: {
    isActive: (state) => state.active,
  },

  actions: {
    setActive(newState) {
			if (newState === true || newState === false) {
				this.active = newState;
				return;
			}

			this.active = false;
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMarketModal, import.meta.hot))
}
