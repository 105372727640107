<template>
	<div
		class="component"
		:class="[
			getComponentCssClass
		]"
	>
		<div 
			id="menu-bar" 
			class="component__container container-fluid menu-bar"
			:class="isOpen ? 'menu-mobile-open' : ''"
		>
			<!-- mobile second level + -->
			<div 
				ref="secondLevelMenu" 
				id="second-level-menu" 
				:class="getSecondLevel()?.active ? 'is-visible' : 'slide-left'"
				class="component__mobile-third-level"
			>
				<span
					id="btn-back-to-mobile-menu" 
					@click="closeSecondLevel()"
					class="component__mobile-third-level--close-button"
				>
					<span class="rotate-180">
						<ArrowIcon />
					</span>
				</span>

				<div
					ref="secondLevelMenuContent"
					id="second-level-menu-content"
					class="component__mobile-third-level__content"
				>
					<ul 
						v-if="getSecondLevel()?.child?.length > 0"
						class="p-0"
						style="position: relative;"
					>
						<li class="explore-item ">
							<div class="dropdown-item position-static" v-if="getSecondLevel()?.cta_explore">
								<NuxtLink 
									v-if="getSecondLevel()?.cta_explore" 
									:to="$url(getSecondLevel()?.link_href)" 
									class="txt-menu" 
									v-html="getSecondLevel()?.title"
									prefetch
								></NuxtLink>
								
								<span class="float-end d-block d-lg-none">
									<ChevronSmall />
								</span>
								
								<NuxtLink 
									:to="$url(getSecondLevel()?.link_href)" 
									class="btn btn-sm btn-outline-primary d-lg-none"
									prefetch
								>
									{{ $t('explore') }}
								</NuxtLink>
							</div>
	
							<div class="dropdown-item" v-else>
								<span class="txt-menu" v-html="getSecondLevel()?.title"></span>
							</div>
						</li>
	
						<li
							v-if="getSecondLevel()?.child?.length > 0"
							v-for="(submenuItem, subKey, subIndex) in getSecondLevel()?.child" 
							:key="submenuItem.id"
						>
							<a 
								v-if="submenuItem.link_target == '_blank'" 
								class="nav-link" 
								aria-current="page" 
								:href="submenuItem.link_href" 
								v-html="submenuItem?.title" 
								target="_blank"
							>
							</a>
	
							<a
								v-else-if="submenuItem.link_target == '#'" 
								class="dropdown-item no-after" 
								:class="{ 
									'border-0': menuItem?.explore && 
									(subIndex == 0 || 
									(menuItem?.two_cols && subIndex == 1))
								}" 
								v-html="submenuItem?.title"
							>
							</a>
	
							<p
								v-else-if="submenuItem.link_href === ''"
								class="dropdown-item" 
								:class="{ 'border-0': menuItem?.explore && 
									(subIndex == 0 || 
									(menuItem?.two_cols && subIndex == 1)) }" 
								:to="$url(submenuItem.link_href)" 
								v-html="submenuItem.title"
								prefetch
							>
							</p>
							
							<NuxtLink v-else 
								class="dropdown-item" 
								:class="{ 'border-0': menuItem?.explore && 
									(subIndex == 0 || 
									(menuItem?.two_cols && subIndex == 1)) }" 
								:to="$url(submenuItem.link_href)" 
								v-html="submenuItem.title"
								prefetch
							>
							</NuxtLink>
	
							<ul v-if="submenuItem.child">
								<li 
									v-for="thirdLevelItem in submenuItem.child" 
									:key="thirdLevelItem.id"
								>
									<a 
										v-if="thirdLevelItem.link_target == '_blank'" 
										aria-current="page" 
										:href="thirdLevelItem.link_href" 
										v-html="thirdLevelItem.title" 
										target="_blank"
									>
									</a>
	
									<NuxtLink 
										v-else 
										:to="$url(thirdLevelItem.link_href)"
										prefetch
									>
										<span>{{ thirdLevelItem.title }}</span>
									</NuxtLink>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
	
			<!-- nav top bar -->
			<nav class="component__navbar navbar navbar-expand-lg top-navbar pb-3 pt-1 py-lg-1">
				<div class="container-fluid px-0">
					<!-- Logo -->
					<NuxtLink
						ref="logoElement"
						:to="`${localePath('index')}/${getCurrentLangSwitcherItem()?.slug || ''}`"
						prefetch
						class="component__logo navbar-brand p-0 m-0 logo-bu text-center"
						:class="[
							getLogoClassName()
						]"
					>
						<div v-if="!theLogo && showFallbackLogo">
							<img src="/logo_lallemand.png" alt="Lallemand" class="component__logo--fallback">
						</div>

						<div 
							v-if="theLogo" 
							v-html="theLogo"
							id="mainLogo" 
						></div>

						<div 
							v-if="$i18n.locale == 'ja'" 
							class="pt-1 fs-16 fw-semibold"
						>
							{{ $t('lan_bu_name') }}
						</div>
					</NuxtLink>
	
					<!-- Burger -->
					<button
						class="component__navbar--burger navbar-toggler"
						:class="isOpen ? '' : 'collapsed'" 
						type="button" 
						data-bs-toggle="collapse" 
						data-bs-target="#navbarScroll" 
						aria-controls="navbarScroll" 
						aria-expanded="false" 
						@click="toggleMenu()" 
						:aria-label="$t('toggle_navigation')"
					>
						<span class="navbar-toggler-icon"></span>
					</button>
	
					<!-- SearchForm -->
					<div
						v-if="showSearchForm"
						class="component__search-wrapper col-12 col-lg-3 navbar-search-wrapper"
						:class="isOpen ? 'is-hidden' : 'is-visible'"
					>
						<HeaderSearch />
					</div>

					<!-- LALPROBIOME - newsletter -->
          <NuxtLink
					  v-if="SITE_NAME == 'lalprobiome'"
						:to="$url(`${localePath('index')}/${getCurrentLangSwitcherItem()?.slug || ''}/pet-update-newsletter`)" 
						id="btn-newsletter" 
						class="btn btn-sm btn-primary btn-icon icon-end mt-5 mt-lg-0 d-none d-lg-block"
						target="_self"
						prefetch
					>
						<span>
							Newsletter
						</span>
						<ArrowIcon />
					</NuxtLink>

					<!-- brewing retailer portal nav -->
					<div
						v-if="getActiveAuthBoolean() && showLoginButton"
						class="component__navbar__auth auth-desktop" 
					>
						<div class="component__navbar__auth__sign-in">
							<NuxtLink
								v-if="!isAuthenticated()"
								:to="`${$url(getLoginPage)}`" 
								class="component__navbar__auth__sign-in--button js-login-link"
								:data-url="`${$url(getLoginPage)}`"
							>
								<LogInIcon class="component__navbar__auth__sign-in--button-icon" />
								<span>
									{{ $t('brew_retailer_portal') }}
								</span>
							</NuxtLink>

							<div
								v-if="isAuthenticated()"
								class="component__navbar__auth__sign-in--dropdown dropdown"
							>
								<button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									{{ $t('brew_retailer_portal') }}
								</button>

								<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									<li>
										<NuxtLink
											:to="`${$url(getSecureHome)}`" 
											class="dropdown-item"
										>
											{{ $t('brew_go_to_portal') }}
										</NuxtLink>
									</li>

									<li><a class="dropdown-item" href="#" @click="doLogOut">{{ $t('brew_logout') }}</a></li>
								</ul>
							</div>
						</div>
					</div>

					<!-- LSC retailer portal nav -->
					<div
						v-if="SITE_NAME === 'lsc'"
						class="navbar__auth auth-desktop"
					>
						<div class="navbar__auth__sign-in">
							<a
								href="https://lsc-documentation.lallemand.com/"
								target="_blank"
								class="navbar__auth__sign-in--button js-login-link"
							>
								<LogInIcon class="navbar__auth__sign-in--button-icon" />
								<span>
									{{ $t('distributor_portal') }}
								</span>
							</a>
						</div>
					</div>
	
					<span 
						v-if="businessUnits?.length > 0"
						class="d-none d-lg-block text-black extra-small-font text-decoration-none" 
						type="button" 
						data-bs-toggle="offcanvas" 
						data-bs-target="#listBU" 
						aria-controls="listBU"
						@click="toggleBU()"
					>
						<EcosystemIcon /> Lallemand
					</span>
				</div>
			</nav>
	
			<!-- business units nav -->
			<div 
				v-if="businessUnits?.length > 0"
				class="offcanvas offcanvas-end"
				:class="businessUnitsNavIsOpen ? 'is-open show' : ''"
				tabindex="-1" 
				id="listBU"
				ref="listBU" 
				aria-labelledby="listBULabel"
			>
				<div class="offcanvas-body">
					<div class="row pb-4">
						<div class="col-12 mb-4">
							<button
								@click="toggleBU()"
								type="button" 
								class="btn-close float-end" 
								data-bs-dismiss="offcanvas" 
								:aria-label="$t('close')">
							</button>
						</div>
	
						<div class="col-md-5 navbar-brand mb-3">
							<NuxtLink
								class="navbar-brand p-0 m-0" 
								:to="localePath('index')"
								prefetch
							>
							<template v-if="businessUnitsNavIsOpen">
								<LogoLallemand />
							</template>	
							</NuxtLink>
						</div>
	
						<div class="col-md-7">
							<p>{{ $t('global_leader') }}</p>
							<a href="https://www.lallemand.com/" target="_blank" class="btn btn-sm btn-outline-light btn-icon">
								{{ $t('lallemand_learn_more') }}
								<template v-if="businessUnitsNavIsOpen">
									<Arrow45Icon />
								</template>
							</a>
						</div>
	
						<div class="col-12 mt-4 pt-3 border-top">
							<p class="font-bebas-neue fw-bold fs-2">{{ $t('lallemand_explore') }}</p>
							<a 
								v-for="(businessUnit, index) in businessUnits" 
								:key="index" 
								:href="businessUnit.link_href" 
								class="div-bu d-block pt-2" 
								target="_blank"
								:title="businessUnit.name"
							>
								<span v-if="businessUnit.icon" class="icon-bu" v-html="businessUnit.icon"></span>
								<span v-else class="icon-bu-empty"></span>
								<span 
									class="font-bebas-neue text-uppercase fw-bold fs-4 ms-2" 
									v-html="businessUnit.name"
								></span>
								<span class="extra-small-font float-end view-btn mt-2">
									{{ $t('view_website') }}
									<template v-if="businessUnitsNavIsOpen">
										<ChevronSmall />
									</template>
								</span>
	
								<div class="bu-border pt-1" :style="'border-bottom: 2px solid ' + businessUnit.color"></div>
							</a>
						</div>
					</div>
				</div>
			</div>
	
			<!-- main nav -->
			<nav 
				class="component__main-menu-wrapper navbar navbar-expand-lg main-menu-wrapper"
				:class="isOpen ? 'show' : ''" 
			>
				<div class="container-fluid px-0">
					<div 
						class="component__main-menu-wrapper__menu collapse navbar-collapse main-menu"
						:class="isOpen ? 'show' : 'collapse'"
						id="navbarScroll"
						ref="navbarScroll"
					>
						<ul
							v-if="menu?.length > 0"
							id="main-navbar" 
							class="component__navbar__menu navbar-nav me-auto mb-2 my-lg-0 navbar-nav-scroll"
						>
							<!-- first level -->
							<li
								v-for="(menuItem, index) in menu" 
								:key="index"
								class="component__navbar__menu--item nav-item"
								:class="{ dropdown: menuItem?.child }"
								@mouseenter="navItemMouseEnter(index, menuItem)"
								@mouseleave="navItemMouseLeave(index)"
							>
								<NuxtLink 
									v-if="menuItem?.child" 
									class="component__navbar__menu--link nav-link dropdown-toggle ml-0"
									:class="[
										 menuItem?.child ? 'has-child' : '',
									]"
									ref="dropdownToggle"
									:to="$url(menuItem?.link_href)"
									data-bs-toggle="dropdown" 
									:aria-expanded="false" 
									:id="'navLink'+index"
									@click.prevent="openSecondLevel(menuItem || null)"
									prefetch
									:title="menuItem?.title"
									role="button"
								>
									<span v-html="menuItem?.title"></span>
								</NuxtLink>
	
								<a 
									v-else-if="menuItem?.link_target == '_blank'"
									class="component__navbar__menu--link nav-link"
									aria-current="false" 
									:href="menuItem?.link_href" 
									target="_blank"
									:title="menuItem?.title"
								>
									<span v-html="menuItem?.title"></span>
								</a>
	
								<NuxtLink v-else 
									class="component__navbar__menu--link nav-link"
									:to="$url(menuItem?.link_href)"
									prefetch
								>
									<span v-html="menuItem?.title"></span>
								</NuxtLink>
	
								<!-- second level -->
								<ul 
									v-if="menuItem?.child" 
									class="component__navbar__submenu dropdown-menu" 
									:class="{
										'menu-col-2-image' : menuItem?.featured_image, 
										'menu-col-2' : menuItem?.display_two_columns,
										'show': menuItem?.isOpen
									}" 
									:aria-labelledby="'navLink'+index" :id="'submenu-'+index"
									:style="[`min-height: ${getMinHeight()}px`]"
								>
									<li class="component__navbar__submenu--item is-explore-item explore-item">
										<div
											v-if="menuItem?.cta_explore" 
											class="component__navbar__submenu--item dropdown-item"
										>
											<NuxtLink 
												v-if="menuItem?.cta_explore" 
												:to="$url(menuItem?.link_href)" 
												class="txt-menu"
												prefetch
												:title="menuItem?.title"
											>
												<span v-html="menuItem?.title"></span>
											</NuxtLink>
											
											<span class="float-end d-block d-lg-none">
												<ChevronSmall />
											</span>
											
											<NuxtLink
												v-if="menuItem?.link_href !== ''"
												:to="$url(menuItem?.link_href)" 
												class="btn btn-sm btn-outline-primary float-end d-none d-lg-inline-block"
												prefetch
												:title="$t('explore')"
											>
												{{ $t('explore') }}
											</NuxtLink>
										</div>
	
										<div
											class="component__navbar__submenu--item dropdown-item" 
											v-else
										>
											<span class="txt-menu" v-html="menuItem?.title"></span>
										</div>
									</li>
	
									<li 
										v-for="(submenuItem, subKey, subIndex) in menuItem?.child"
										:key="submenuItem.id"
										class="component__navbar__submenu--item"
									>
										<a 
											v-if="submenuItem.link_target == '_blank'" 
											class="nav-link" 
											:class="submenuItem.class ? submenuItem.class.join(' ') : ''"
											aria-current="page" 
											:href="submenuItem.link_href"
											target="_blank"
											:title="submenuItem.title"
										>
											<span v-html="submenuItem.title"></span>
										</a>
	
										<a 
											v-else-if="submenuItem.link_target == '#'" 
											class="component__navbar__submenu--item-inner dropdown-item no-after"
											:title="submenuItem.title"
											:class="[{ 'border-0': menuItem?.cta_explore && 
												(subIndex == 0 ||
												(menuItem?.display_two_columns && subIndex == 1)),
												'no-after' : submenuItem.link_href === ''
											}, submenuItem.class ? submenuItem.class.join(' ') : '']" 
										>
											<span v-html="submenuItem.title"></span>
										</a>

										<p
											v-else-if="submenuItem.link_href === ''"
											class="component__navbar__submenu--item-inner dropdown-item" 
											:class="[{ 'border-0': menuItem?.cta_explore && 
												(subIndex == 0 ||
												(menuItem?.display_two_columns && subIndex == 1)),
												'no-after' : submenuItem.link_href === ''
											}, submenuItem.class ? submenuItem.class.join(' ') : '']" 
										>
											<span v-html="submenuItem.title"></span>
										</p>
	
										<NuxtLink
											v-else 
											class="component__navbar__submenu--item-inner dropdown-item" 
											:class="[{ 'border-0': menuItem?.cta_explore && 
												(subIndex == 0 ||
												(menuItem?.display_two_columns && subIndex == 1)),
												'no-after' : submenuItem.link_href === ''
											}, submenuItem.class ? submenuItem.class.join(' ') : '']" 
											:to="$url(submenuItem.link_href)"
											:title="submenuItem.title"
											prefetch
										>
											<span v-html="submenuItem.title"></span>
										</NuxtLink>
	
										<ul v-if="submenuItem?.child">
											<li 
												v-for="thirdLevelItem in submenuItem.child" 
												:key="thirdLevelItem.id"
											>
												<a 
													v-if="thirdLevelItem.link_target == '_blank'" 
													aria-current="page" 
													:href="thirdLevelItem.link_href"
													:class="thirdLevelItem.class ? thirdLevelItem.class.join(' ') : ''"
													target="_blank"
													:title="thirdLevelItem.title"
												>
													<span v-html="thirdLevelItem.title"></span>
												</a>
	
												<NuxtLink 
													v-else 
													:to="$url(thirdLevelItem.link_href)"
													:class="thirdLevelItem.class ? thirdLevelItem.class.join(' ') : ''"
													prefetch
													:title="thirdLevelItem.title"
												>
													<span v-html="thirdLevelItem.title"></span>
												</NuxtLink>
											</li>
										</ul>
									</li>
	
									<li v-if="menuItem?.featured_image?.image" class="menu-image">
										<div class="image-menu-wrapper text-center">
											<!-- @TODO - need crops from backend -->
											<img
												loading="lazy" 
												:src="menuItem?.featured_image?.image" 
												:alt="menuItem?.title"
												ref="menuImage"
												width="500"
  											height="500"
												:class="`menu-image-${slugify(menuItem.title)}`"
											/>
											<br v-if="menuItem?.featured_image?.cta_href">
											<NuxtLink 
												v-if="menuItem?.featured_image?.cta_href" 
												:to="menuItem?.featured_image?.cta_href" 
												:target="menuItem?.featured_image?.cta_target"
												class="link-call-to-action" 
												prefetch
											>
												<span v-html="menuItem?.featured_image?.cta_label"></span>
											</NuxtLink>
										</div>
									</li>
								</ul>
							</li>
						</ul>
	
						<NuxtLink
							v-if="headerCTA?.cta_href"
							:to="headerCTA?.cta_target === '_blank' ? headerCTA?.cta_href : $url(headerCTA?.cta_href)" 
							id="btn-contact" 
							class="component__navbar--btn-contact btn btn-light btn-sm btn-icon icon-end mt-5 mt-lg-0"
							:target="headerCTA?.cta_target || '_self'"
							prefetch
							:title="headerCTA?.cta_label"
						>
							<span>
								{{ headerCTA?.cta_label }}
							</span>
							<ArrowIcon />
						</NuxtLink>

						<!-- brewing retailer portal nav -->
						<div
							v-if="getActiveAuthBoolean()"
							class="component__navbar__auth auth-mobile" 
						>
							<div class="component__navbar__auth__sign-in">
								<NuxtLink
									v-if="!isAuthenticated()"
									:to="`${$url(getLoginPage)}`" 
									class="component__navbar__auth__sign-in--button"
								>
									<LogInIcon class="component__navbar__auth__sign-in--button-icon" />
									<span>
										{{ $t('brew_retailer_portal') }}
									</span>
								</NuxtLink>

								<div
									v-if="isAuthenticated()"
									class="component__navbar__auth__sign-in--dropdown dropdown"
								>
									<button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
										{{ $t('brew_retailer_portal') }}
									</button>

									<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
										<li>
											<NuxtLink
												:to="`${$url(getSecureHome)}`" 
												class="dropdown-item"
											>
											{{ $t('brew_go_to_portal') }}
											</NuxtLink>
										</li>

										<li><a class="dropdown-item" href="#" @click="doLogOut">{{ $t('brew_logout') }}</a></li>
									</ul>
								</div>
							</div>
						</div>

						<!-- LSC retailer portal nav -->
						<div
							v-if="SITE_NAME === 'lsc'"
							class="navbar__auth auth-mobile" 
						>
							<div class="navbar__auth__sign-in">
								<a
									href="https://lsc-documentation.lallemand.com/"
									target="_blank"
									class="navbar__auth__sign-in--button js-login-link"
								>
									<LogInIcon class="navbar__auth__sign-in--button-icon" />
									<span>
										{{ $t('distributor_portal') }}
									</span>
								</a>
							</div>
						</div>
	
						<span 
							class="component__navbar--lang-switcher svg-primary-path small-13-font text-center my-3 ms-2"
							:data-bs-toggle="'modal'" 
							:data-bs-target="'#regionsModal'" 
							:aria-controls="'#regionsModal'" 
							id="language-switch"
							ref="languageSwitch"
							@click="openModal()"
						>
							<EarthIcon />
							{{ $t('locale_'+$i18n.locale) }}
						</span>
					</div>
				</div>
			</nav>
		</div>
	
		<NotificationBar
			:data="notificationData"
		/>
	</div>
</template>

<script setup>
	import { storeToRefs } from 'pinia';
	import { lockBody, unLockBody } from '../utils/dom.js';
	
	// icons import
	import LogoLallemand from './icons/logo-lallemand.vue';
	import ArrowIcon from './icons/arrow.vue';
	import ChevronSmall from './icons/chevron-small.vue';
	import EcosystemIcon from './icons/ecosystem-small.vue';
	import Arrow45Icon from './icons/arrow-45.vue';
	import EarthIcon from './icons/earth.vue';
	import LogInIcon from './icons/lock.vue';

	const config = useRuntimeConfig();
	const { ACTIVE_AUTH, ACTIVE_MARKET, SITE_NAME } = config.public || {};

	const { $url } = useNuxtApp();
	const localePath = useLocalePath();
	const route = useRoute();
	const { locale } = useI18n();
	const componentGlobalData = ref(null);
	const componentLayoutData = ref(null);

	const businessUnitsNavIsOpen = ref(false);
	
	const marketModal = useMarketModal();

	const authStore = useAuth();
	const { logUserOut } = authStore;
	const { authenticated } = storeToRefs(authStore);

	const pageStore = usePage();
	const { setPageRedirect } = pageStore;
	const { pageRedirect } = storeToRefs(pageStore);

	// utility
	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	};

	const getActiveAuthBoolean = () => {
		if (ACTIVE_AUTH === 'false') {
			return false
		}

		return true;
	};

	const isAuthenticated = () => {
		return authenticated?.value || false;
	};

	const getLogoClassName = () => {
		return `logo-bu-${slugify(SITE_NAME)}`
	}

	// data fetch
	const globalFetchUrl = '/api/global';
	const { data: globalData } = useNuxtData(globalFetchUrl);
	if (!globalData.value && !import.meta.client) {
		await useFetch(globalFetchUrl, {
			key: globalFetchUrl,
			default: () => {
				return globalData.value;
			}
		});
	}

	if (globalData.value) {
		componentGlobalData.value = globalData.value;
	}

	const getCurrentMarketSlug = () => {
		//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
		// FIND WHY AND FIX IT (maybe  because it's in app.vue and not in <NuxtPage /> ?)
		if (getActiveMarketBoolean()) {
			const params = route?.params;
			const slugs = params?.market || 'europe';

			if (slugs.includes('robot') || slugs.includes('robots')) {
				return null
			}
	
			if (!slugs.includes('robot') && !slugs.includes('robots')) {
				return slugs;
			}
		}

		return '';
	};

	const layoutFetchUrl = getEndpointUrl(
		'layout', 
		getActiveMarketBoolean(),
		locale.value,
		'',
		getCurrentMarketSlug()
	);

	const { data: layoutData } = useNuxtData(layoutFetchUrl);
	if (!layoutData.value && !import.meta.client) {
		await useFetch(layoutFetchUrl, {
			key: layoutFetchUrl,
			default: () => {
				return layoutData.value;
			}
		});
	}

	if (layoutData.value) {
		componentLayoutData.value = layoutData.value;
	}

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('---- Header ----');
	// console.log('globalData.value: ', globalData.value);
	// console.log('layoutData.value: ', layoutData.value);
	// console.log(' ');
	// console.log('layoutFetchUrl: ', layoutFetchUrl);
	// console.log('route.params: ', route.params);
	// console.log('layoutData?.value?.homepage_slug: ', layoutData?.value?.homepage_slug);
	// console.log('getCurrentMarketSlug(): ', getCurrentMarketSlug());

	const pageFetchUrl = getEndpointUrl(
		'content', 
		getActiveMarketBoolean(),
		locale.value,
		layoutData?.value?.homepage_slug,
		getCurrentMarketSlug()
	);

	const { data: pageData } = useNuxtData(pageFetchUrl);
	if (!pageData.value && getCurrentMarketSlug() !== null && !!import.meta.client) {
		await useFetch(pageFetchUrl, {
			key: pageFetchUrl,
			default: () => {
				return pageData.value;
			}
		});
	}

	const restructureMenu = (data = null) => {
		if (data) {
			const originalMenu = data || [];
			const out = originalMenu.map((firstLevel, index) => {
				return {
					...firstLevel,
					isOpen: false,
				}
			});
	
			return out;
		}

		return [];
	}

	const { markets } = globalData?.value || {};
	const { hide_elements } = layoutData?.value || [];

	const getCurrentLangSwitcherItem = () => {
		let out = '';

		if (getActiveMarketBoolean()) {
			Object.keys(markets).forEach(function(k, i) {
				const market = markets[k];
				const match = market.find((m) => {
					return m.slug === getCurrentMarketSlug();
				});
				
				if (match) {
					out = match
				}
			})
		}

		return out;
	}

	// reactive states
	const menu = ref(restructureMenu(layoutData?.value?.main_menu));
	const headerCTA = ref(layoutData?.value?.header_cta || {});
	const activeSecondMenu = ref({});
	const businessUnits = ref(layoutData?.value?.business_units);
	const businessUnitsIsOpen = ref(false);
	const logoElement = ref(null);

	const notificationData = ref(layoutData?.value?.notification);

	const isOpen = ref(false);
	const isMobile = ref(false);

	const subMenuDesktopMinHeight = ref(0);

	const retailerUrls = ref({});
	const showFallbackLogo = ref(false);
	const theLogo = ref(null);

	const showSearchForm = ref(true);
	const showLoginButton = ref(true);

	// dom ref
	const languageSwitch = ref([]);
	const dropdownToggle = ref(null);
	const secondLevelMenu = ref(null);
	const secondLevelMenuContent = ref(null);
	const menuImage = ref([]);

	// methods
	const toggleMenu = () => {
		if (process.client) {
			if (window.innerWidth <= 991) {
				if (isOpen.value) {
					showGeolocation();
					unLockBody();
					closeSecondLevel();
					isOpen.value = false;
					
					return;
				}
				
				hideGeolocation();
				lockBody();
				isOpen.value = true;
			}
		}
	}
	
	const openSecondLevel = (obj) => {
		if (process.client) {
			if (window.innerWidth <= 991) {
				activeSecondMenu.value = {
					...obj,
					active: true,
				}
	
				return
			}
	
			activeSecondMenu.value = {
				...obj,
				active: false,
			}
		}
	}

	const closeSecondLevel = () => {
		if (process.client) {
			if (window.innerWidth <= 991) {
				if (activeSecondMenu.value) {
					activeSecondMenu.value = {
						...activeSecondMenu.value,
						active: false,
					};
				}
			}
		}
	}

	const toggleBU = () => {
		if (process.client) {
			if (window.innerWidth >= 992) {
				businessUnitsNavIsOpen.value = !businessUnitsNavIsOpen.value;
		
				if (businessUnitsNavIsOpen.value) {
					lockBody();
				}
		
				if (!businessUnitsNavIsOpen.value) {
					unLockBody();
				}
			}
		}
	}

	const navItemMouseEnter = (receivedIndex, receivedMenu = null) => {
		if (process.client) {
			if (window.innerWidth >= 992) {
				menu.value[receivedIndex].isOpen = true;

				// if there is a featured image
				const featuredImage = receivedMenu?.featured_image?.image || false;
				// console.log(' ');
				// console.log(' ');
				// console.log(' ');
				// console.log('receivedIndex: ', receivedIndex);
				// console.log('receivedMenu: ', receivedMenu);
				// console.log('featuredImage: ', featuredImage);

				if (featuredImage) {
					nextTick(() => {
						if (menuImage?.value?.length > 0) {
							menuImage?.value.forEach((item) => {
								const srcImage = item.src;

								
								if (srcImage === featuredImage) {
									const classImage = item.className;
									// console.log(' ');
									// console.log(' ');
									// console.log('item: ', item);
									// console.log('classImage: ', classImage);
									if (classImage) {
										const domImage = document.querySelector(`.${classImage}`);
										const height = domImage.clientHeight; // we use clientHeight so it returns undefined if the image is hidden
	
										if (height) {
											subMenuDesktopMinHeight.value = height;
										}
									}
								}
							});
						}
					})
				} else {
					subMenuDesktopMinHeight.value = -75;
				}
			}
		}
	}

	const navItemMouseLeave = (receivedIndex) => {
		if (process.client) {
			if (window.innerWidth >= 992) {
				menu.value[receivedIndex].isOpen = false;
			}
		}
	}

	const closeDesktopSecondLevel = () => {
		if (menu?.value?.length > 0) {
			menu.value.forEach((element) => {
				element.isOpen = false;
			})
		}
	}

	const openModal = () => {
		marketModal.setActive(true);
	}

	const hideGeolocation = () => {
		if (process.client) {
			const geolocElement = document.querySelector('.js-geolocation');
			if (geolocElement) {
				geolocElement.style.display = 'none';
			}
		}
	}
	
	const showGeolocation = () => {
		if (process.client) {
			const geolocElement = document.querySelector('.js-geolocation');
			if (geolocElement) {
				geolocElement.style.display = 'block';
			}
		}
	}

	const getMinHeight = () => {
		return subMenuDesktopMinHeight.value + 75;
	}

	const doLogOut = () => {
		logUserOut();
	}

	// getters
	const getSecondLevel = () => {
		return activeSecondMenu?.value || {};
	}

	const getBUisOpen = () => {
		return businessUnitsNavIsOpen.value;
	}

	const getComponentCssClass = computed(() => {
		if (locale.value === 'zh-hans') {
			return 'mandarin-text';
		}

		return '';
	});

	const getUser = pageData?.value?.user || {};
	const getLoginPage = getUser?.urls?.login || '';
	const getSecureHome = getUser?.urls?.home || '';

	// lifecycle
	if (hide_elements?.length > 0) {
		hide_elements.forEach((element) => {
			if (element === 'search') {
				showSearchForm.value = false;
			}

			if (element === 'login') {
				showLoginButton.value = false;
			}
		});
	}

	if (layoutData?.value?.logo) {
		// assign logo
		theLogo.value = layoutData?.value?.logo;
		showFallbackLogo.value = false;
	}

	// show fallback logo
	if (!layoutData?.value?.logo) {
		showFallbackLogo.value = true;
	}
	onMounted(() => {
		// if (layoutData?.value?.logo) {
			// render SVGs
			// renderSVG('mainLogo', layoutData?.value?.logo);
		// }

		// show fallback logo
		if (!layoutData?.value?.logo) {
			showFallbackLogo.value = true;
		}
	})

	// watcher
	watch(route, async () => {
		closeDesktopSecondLevel();

		if (isOpen.value) {
			unLockBody();
			closeSecondLevel();
			isOpen.value = false;
			showGeolocation();
			
			return;
		}

		const layoutDataValidData = Object.keys(layoutData?.value || {})?.length > 0;
		if (!layoutDataValidData && componentGlobalData.value?.default_market_slug) {
			const newLayoutFetchUrl = getEndpointUrl(
				'layout', 
				getActiveMarketBoolean(),
				locale.value,
				'',
				componentGlobalData.value?.default_market_slug
			);

			const { data: newLayoutData } = await useFetch(newLayoutFetchUrl);
			if (newLayoutData?.value?.main_menu) {
				menu.value = restructureMenu(newLayoutData?.value?.main_menu);
			}

			// update ref
			openedMenuMap.value = structureOpenedMenuMap(menu.value);
			headerCTA.value = newLayoutData?.value?.header_cta || {};
			businessUnits.value = newLayoutData?.value?.business_units;
			notificationData.value = newLayoutData?.value?.notification;
			
			if (newLayoutData?.value?.logo) {
				showFallbackLogo.value = false;
				theLogo.value = newLayoutData.value.logo;
			}
			nextTick(() => {
				getLoginPage.value = newLayoutData?.header_login?.link_href || '';
			})
		}
	}, {
		deep: true
	});

	watch(pageRedirect, async () => {
		if (pageRedirect.value?.redirect === true) {
			const layoutFetchUrl = getEndpointUrl(
				'layout', 
				getActiveMarketBoolean(),
				locale.value,
				'',
				pageRedirect.value.market
			);

			const { data: newLayoutData } = await useFetch(layoutFetchUrl, {
				key: layoutFetchUrl,
				default: () => {
					return layoutData.value;
				}
			});

			// update ref
			menu.value = restructureMenu(newLayoutData?.value?.main_menu);
			headerCTA.value = newLayoutData?.value?.header_cta || {};
			businessUnits.value = newLayoutData?.value?.business_units;
			notificationData.value = newLayoutData.value.notification;

			setPageRedirect({redirect: false, market: pageRedirect.value.market});
		}
	}, {
		deep: true
	});

	watch(authenticated, () => {
		if (authenticated.value === false) {
			// console.log('authenticated.value: ', authenticated.value);

			const loginPageUrl = layoutData?.value?.header_login?.link_href || '';
			const relativeLoginUrl = $url(loginPageUrl);
			const relativeHomepageUrl = `${localePath('index')}/${getCurrentLangSwitcherItem()?.slug || ''}`

			if (route.fullPath !== relativeLoginUrl) {
				window.location.href = relativeHomepageUrl;
			}
		}
	}, {
		deep: true
	});
</script>

<style lang="scss" scoped>
	// BEM
	.component {
		.offcanvas {
			transition: all var(--ease-in) .25s!important;
			transform: translate3d(100%, 0, 0);

			&.is-open {
				transform: translate3d(0, 0, 0);
			}

		}

		// __container (.menu-bar)
		&__container {
			--mobile-nav-top-offset: 55px;
			height: auto!important;
		}

		// __navbar (.top-navbar)
		&__navbar {
			&--burger {
				position: relative;
				z-index: 115;
			}

			&--lang-switcher {
				display: block;
				margin-inline: 0!important;
			}

			// --item (.nav-item)
			&--item {
				font-size: 1rem;
				color: var(--priamry)!important;
			}

			// __submenu (.dropdown-menu)
			&__submenu {
				border: 0;

				a {
					color: var(--primary)!important;
				}

				p {
					color: var(--primary)!important;
				}

				// --item (.dropdown-item)
				&--item {
					&:hover, &:focus {
						background-color: transparent!important;
					}
					&::after {
						content: "";
						width: 30px;
						height: 30px;
						background: url(~/assets/images/svg/chevron-small.svg) no-repeat;
						top: 20px;
						right: 6px;
						position: absolute;
					}
					&.no-after{
						&::after {
							background: none;
						}
					}

					&.is-explore-item & {
						border-bottom: 1px solid var(--primary);
						font-size: 1.25rem;
						font-family: "bebas-neue-pro", Arial, helvetica, sans-serif;
						padding: 15px 18px 15px 0px;
						color: var(--primary) !important;
						font-weight: 700;
						// &:after {
						// 	background: none;
						// }

						.btn {
							margin-top: 16px;
							padding: 10px 20px!important;
							font-family: "Open sans", sans-serif;
							font-size: 0.875rem;
							font-weight: 500;
						}

						.txt-menu {
							display: inline-block;
							white-space: break-spaces;
							text-transform: uppercase;
						}
					}
				}
			}

			// __auth
			&__auth {
				margin-inline-start: auto;
				padding-inline: 15px;

				&__sign-in {
					&--button {
						display: flex;
						align-items: center;
						justify-content: center;
						line-height: 1;

						&-icon {
							margin-inline-end: 4px;
							width: 17px;
							height: auto;
							color: rgba(50, 62, 72, 1);
						}

						span {
							font-size: 12px;
							color: var(--black);
						}
					}

					&--dropdown {
						button {
							background: transparent;
							border: none;
							font-size: 14px;
							color: var(--primary);
							text-shadow: 0 0 var(--primary);
							&:after {
								top: -5px;
								width: 12px;
								height: 12px;
								transform: rotate(0);
							}
						}

						ul {
							left: 0;
						}
					}
				}

				&.auth-mobile {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 8px;
					min-height: 57px;
				}

				&.auth-desktop {
					display: none;
				}
			}
		}

		// __search-wrapper (. navbar-search-wrapper)
		&__search-wrapper {
			opacity: 1;
			pointer-events: all;
			transition: all var(--ease-in) .25s;
			&.is-hidden {
				opacity: 0;
				pointer-events: none;
			}
		}

		// __main-menu-wrapper (.main-menu-wrapper)
		&__main-menu-wrapper  {
			position: absolute;
			top: var(--mobile-nav-top-offset);
			left: 0;
			right: 0;
			align-items: flex-start;
			height: calc(100vh - var(--mobile-nav-top-offset));
			opacity: 0;
			background: white;
			pointer-events: none;
			transition: all var(--ease-in) .25s;
			z-index: 80;

			// __menu (.main-menu)
			&__menu {
				display: block!important;
				opacity: 0;
				height: 100%;
				pointer-events: none;
				transition: all var(--ease-in) .25s;
	
				// states
				&.show {
					opacity: 1;
					pointer-events: all;
				}

				*:focus {
					outline: none;
					background: none;
				}
			}

			// states
			&.show {
				opacity: 1;
				pointer-events: all;
			}
		}

		// __logo
		&__logo {
			:deep(svg) {
				width:100px;
				height:auto;
			}

			&.logo-bu {
				:deep(svg) {
					width: 175px;
				}
			}
		}

		// __mobile-third-level (#second-level-menu-content)
		&__mobile-third-level {
			transition: all var(--ease-in) .2s;
			overflow-y: scroll;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: var(--white);
			z-index: 110;

			li {
				display: block;
				list-style: none;
				border-bottom: 1px solid var(--light-primary);
				padding: 20px 0;
				margin: 0 20px;
				a, > p {
					font-size: 1rem;
					font-weight: 700;
					color: var(--primary)!important;
				}

				> p {
					margin-block-end: 0;
				}
				a::after, > p::after {
					position: absolute;
					right: 9px;
					width: 1rem;
					height: 0.625rem;
					content: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8 10L12 14L16 10' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
					transform: rotate(270deg);
					margin-top: 8px;
				}
				ul { /* 3 Level menu mobile */
					padding: 0;
					margin-bottom: 0;
					li:last-child {
						border: none;
						padding-bottom: 0;
					}
					li {
						margin: 0;
					}
					a {
						font-size: 0.938rem;
						font-weight: 400;
						color: var(--primary)!important;
						&::after {
							content: "";
						}
					}
				}
			}

			.explore-item {
				list-style: none;
				border-bottom: 1px var(--light-primary);
				font-family: var(--ff-headings)!important;
				font-size: 1.5rem;
				color: var(--primary)!important;
				text-align: center;
				display: block;
				padding: 16px;
				margin: 0!important;
				.txt-menu {
					font-weight: 700!important;
				}
				a {
					font-size: 1.5rem;
					&::after {
						content: "";
					}
				}
				svg {
					display: none;
				}
			}

			.btn-outline-primary {
				position: absolute;
				top: calc(100% + 65px);
				left: 16px;
				right: 16px;
				height: 43px;
				font-size: 14px!important;
				font-family: var(--ff-sans);
				font-weight: 400;
			}

			&__content {
				padding-bottom: 100px;
				position: relative;
				min-height: 95vh;
				min-width: 320px;
			}
		}

		// MD +
		@include mediaq('>md') {
			// __navbar (.navbar-nav)
			&__navbar {
				&__menu {
					&--item {
						margin-right: 1rem;
	
						a {
							padding-bottom: 10px;
						}
					}
				}
			}
		}

		// LG +
		@include mediaq('>lg') {
			// __search-wrapper (. navbar-search-wrapper)
			&__search-wrapper {
				opacity: 1;
				pointer-events: all;
			}
			
			// __container
			&__container {
				background: white;
			}

			// __navbar (.top-navbar)
			&__navbar {
				border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;

				&__submenu {
					&--item {
						position: relative;
						&:hover, &:focus {
							background: transparent!important;
						}

						> a {
							&:hover, &:focus {
								background: transparent!important;
							}
						}

						&.is-explore-item {
							&::after {
								display: none!important;
								background: none!important;
								opacity: 0!important;
								width: 0px!important;
								height: 0px!important;
							}
						}

						&.is-explore-item & {
							&::after {
								display: none!important;
								background: none!important;
								opacity: 0!important;
								width: 0px!important;
								height: 0px!important;
							}
						}
					}
				}
			}

			// __main-menu-wrapper (.main-menu-wrapper)
			&__main-menu-wrapper {
				position: relative;
				inset: auto;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: nowrap;
				padding: 0;
				height: auto;
				opacity: 1;
				pointer-events: all;

				// __menu (.main-menu)
				&__menu {
					display: flex!important;
					opacity: 1;
					pointer-events: all;

					.navbar-nav {
						.nav-item {
							position: static;

							// little :before hack to have a bigger hover zone
							> a {
								> span {
									position: relative;
									display: inline-block;
									&:before {
										content: '';
										position: absolute;
										top: 100%;
										left: -10px;
										right: -22px;
										height: 20px;
									}
								}
							}

							.dropdown-menu {
								top: 100%;
								left: -100px;
								&:after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									left: 100%;
									width: 100%;
									min-height: 150px;
									max-height: 100%;
									height: 75%;
									// background: rgba(5, 60, 100, 0.5);
								}
							}

							> ul {
								> li {
									> a {
										span {
											display: inline-block;
											width: 100%;
											white-space: break-spaces;
										}
									}
									> ul {
										> li {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
			}

			// __logo
			&__logo {
				margin-inline: 0 10px!important;
			}

			&__navbar {
				padding: 0;

				&-search-wrapper {
					margin-inline: auto!important;
				}

				&__auth {
					// transform: translate3d(0, -2px, 0);

					&.auth-mobile {
						display: none;
					}

					&.auth-desktop {
						display: flex;
					}

					&__sign-in {
						&--dropdown {
							margin-top: -5px;

							button {
								font-size: 12px;
								color: var(--black);
								text-shadow: none;
								&:after {
									top: 3px;
								}
							}
						}
					}
				}

				&__menu {
					flex-grow: 1;
					padding-inline: 0 1rem;

					&--item {
						padding-block: var(--bs-navbar-padding-y);
						&:not(:last-child) {
							margin-right: 10px; 
						}

						> a {
							font-size: 0.85rem;
							height: 100%;
						}
					}

					&--link {
						&.has-child {
							padding-right: 0!important;
						}
					}
				}
			}

			// __logo
			&__logo {
				:deep(svg) {
					width:150px;
				}

				&.logo-bu {
					:deep(svg) {
						width: 255px;
					}
				}
			}

			// __mobile-third-level (#second-level-menu-content)
			&__mobile-third-level {
				display: none!important;
				visibility: hidden!important;
				opacity: 0!important;
				pointer-events: none!important;
			}
		}

		// XL +
		@include mediaq('>xl') {
			// __navbar // __navbar (.top-navbar)
			&__navbar {
				&__menu {
					&--item {
						&:not(:last-child) {
							margin-right: 18px; 
						}

						> a {
							font-size: 1rem;
						}
					}
				}
			}

			// __container
			&__container {
				padding-left: 2rem !important;
    		padding-right: 2rem !important;
			}
		}

		// xxl +
		@include mediaq('>xxl') {
			// __navbar
			&__navbar {
				&__menu {
					&--item {
						&:not(:last-child) {
							margin-right: 25px; 
						}
					}
				}
			}

			&__container {
				padding-left: 6.25rem !important;
    		padding-right: 6.25rem !important;
			}
		}

		// 1600px +
		@include mediaq('>1600px') {
			// __navbar
			&__navbar {
				&__menu {
					&--item {
						&:not(:last-child) {
							margin-right: 35px; 
						}
					}
				}
			}
		}

		// MAX width 991px
		@include mediaq('<lg') {
			// __container (.menu-bar)
			&__container {
				padding-left: 0;
				padding-right: 0;
			} 

			// __navbar (.top-navbar)
			&__navbar {
				padding-top: 1rem !important;
				padding-right: 1.25rem !important;
				padding-left: 1.25rem !important;
				transition: all var(--ease-in) .25s;

				&--burger {
					padding-bottom: 0.3rem!important;
				}

				&--btn-contact {
					display: block;
					padding-top: 1.3125rem!important;
					padding-bottom: 1.3125rem!important;
					max-width: calc(100% - 40px);
					margin: auto;
					border: 0;
				}
			}

			// __main-menu-wrapper (.main-menu-wrapper)
			&__main-menu-wrapper {
				// __menu (.main-menu)
				&__menu {
					position: relative;
					border-top: 1px solid var(--light-primary);
					padding: 0;
					overflow-x: hidden;

					.dropdown-toggle::after {
						transform: rotate(270deg);
						margin-top: 8px;
					}
				}
			}
		}
	}

	// logo style for BU
	.logo-bu-corpo, .logo-bu-baking {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		max-height: 31px;
		max-width: 75px;

		> div {
			display: flex;
		}

		:deep(svg) {
			max-width: 100%;
			width: 100%;
			height: 100%;
			// max-height: 31px;
		}

		@include mediaq('>lg') {
			transform: translate3d(0, -6px, 0);
			max-width: 150px;
			max-height: 46px;
		}
	}

	.logo-bu-plantcare {
		width: 100%;
		max-width: 275px;
		text-align: left!important;

		:deep(svg) {
			max-width: 255px;
			width: 100%!important;
			height: 100%;
			// max-height: 31px;
		}

		@include mediaq('>lg') {
			max-width: 300px;
		}
	}

	.logo-bu-lsc {
		width: 55px;
		transform: scale(2.2) translate3d(10px, -1px, 0);

		:deep(svg) {
			width: 60px;
		}

		@include mediaq('>lg') {
			display: flex;
			width: 100%;
			max-width: 130px;
			transform: none;

			:deep(svg) {
				transform: scale(1.4) translate3d(7px, 0, 0);
				max-width: 130px;
				width: 100%;
				height: 100%;
			}
		}
	}

	// mandarin
	.mandarin-text {
		h1, h2, h3, h4, h5, h6 {
			letter-spacing: 1.45px;
			line-height: 1.45;
		}

		p, li, a {
			letter-spacing: 1.35px;
			line-height: 1.55;
		}

		// xl +
		@include mediaq('>lg') {
			p, li, a {
				letter-spacing: 1.85px;
			}
		}
	}

	// OLD STYLES
	.navbar-toggler-icon {
		background-image: url(~/assets/images/svg/close-small.svg);
		width: 1.7rem;
	}

	.collapsed .navbar-toggler-icon {
		background-image: url(~/assets/images/svg/burger.svg);
		width: 1.5rem;
		height: 1.5rem;
	}

	.navbar-toggler {
		border: 0;
	}

	.slide-left {
		margin-left: 100%;
		svg, li a::after  {
			display: none;
		}
	}

	#main-navbar{
		transition: all var(--ease-in) 1.3s;
	}

	#btn-back-to-mobile-menu {
		transition: all 0.3s;
		position: absolute;
		top: 18px;
		left: 20px;
		z-index: 5;
	}

	#language-switch {
		@include mediaq('>lg') {
			display: none!important;
		}
	}

	.menu-image {
		display: none!important;
	}

	.bu-border {
		width: 0;
		transition: all 0.8s;
	}

	.link-call-to-action {
		background-color: var(--gray-100);
		padding: 10px 20px 12px!important;
		border-radius: 70px;
		font-size: 14px!important;
		font-weight: 500!important;
		color: var(--primary);
		border: 0!important;
	}
	.small-13-font { font-size: .813rem; }
	.extra-small-font { font-size: .75rem; }
	.fs-16 {font-size: 1rem;}


	// min width 991px
	@include mediaq('>lg') {
		.nav-item {
			margin-right: 0.2rem;
		}
		.navbar-expand-lg .navbar-nav .nav-link:first-child {
			padding-left: 0;
		}
		.menu-col-2 {
			width: 97%;
			padding: 25px 80px 50px 100px;
			li:not(:first-child) {
				width: 49%;
				float: left;
			}
			li:nth-child(even) {
				margin-right: 20px;
			}
		}
		.dropdown-item::after {
				right: -8px;
		}
		.navbar-nav {
			.dropdown-menu {
				top: 97%;
				width: 705px;
				padding: 25px 100px 50px;
				border-radius: 0px 0px 10px 0px;
				box-shadow: 0px 9px 10px -6px rgba(28, 53, 94, 0.25), 8px 47px 56px rgba(28, 53, 94, 0.15);
				li a:not(.txt-menu), li > p:not(.txt-menu) {
					margin-block-end: 0;
					padding: 15px 35px 15px 0px;
					font-size: 20px;
					font-weight: 700;
				}
				li.explore-item {
					.dropdown-item {
						position: relative;
						z-index: 1;
					}
				}
				li:not(.explore-item) a, li:not(.explore-item) > p {
					margin-top: -1px;
					border-top: 1px solid var(--light-primary);
					border-bottom: none !important;
				}
				ul { /* 3 Level menu */
					list-style: none;
					padding-left: 0;
					padding-bottom: 10px;
					margin-top: -6px;
					li {
						a {
							border: none!important;
							font-weight: 400!important;
							font-size: 16px!important;
							display: inline-block;
							padding: 8px 18px 8px 0px!important;
						}
					}
					li:last-child {
						margin-bottom: 6px;
					}
				}
			}
			.nav-item {
				position: initial;
				.nav-link {
					position: relative;
					&::before {
						content: "";
						position: absolute;
						bottom: -12px;
						left: 0;
						width: 0;
						transition: all 0.25s;
						border: 0.25rem solid transparent;
						border-radius: 3px 3px 0px 0px;
					}
				}
				&:hover, .show:not(a), .show:not(a), &.menu-open {
					.nav-link {
						color: var(--black)
					}
				}
				&.menu-open .nav-link::after {
					transform: rotate(180deg);
					margin-top: 16px;
				}
				&.dropdown  {
					&:hover, .show:not(a), &.menu-open {
						.nav-link::before {
							width: 108%;
						}
					}
				}
			}
		}
		.navbar-nav .dropdown-menu .explore-item {
			.dropdown-item {
				font-size: 50px;
				padding-top: 0;
				padding-right: 0;
			}
			.txt-menu {
				width: 75%;
			}
		}
	}

	// MAX width 991px
	@include mediaq('<lg') {
		.navbar-collapse {
			.btn {
				// background-color:var(--primary);
				// color: var(--primary);
				width: 100%;
				svg {
					display: none;
				}
			}
		}

		.menu-mobile-open {
			height: 100vh;
		}

		.nav-item {
			border-bottom: 1px solid var(--light-primary);
			padding-bottom: 8px;
			padding-top: 8px;
			font-weight: 700;
			margin: 0 20px;
			.dropdown-toggle::after {
				right: 0;
				width: 1rem;
			}
			.dropdown-menu {
				display: none;
			}
		}
	}

	// min width 1070px
	@include mediaq('>1170px') {
		.nav-item {
			margin-right: 1rem;
		}
	}

	// min width 1280px
	@include mediaq('>1280px') {
		.nav-item {
			margin-right: 2rem;
		}

		.menu-col-2, .menu-col-2-image {
			width: 1230px!important;
		}

		.menu-col-2-image {
			width: 97%;
			padding: 25px 80px 50px 100px;
			min-height: 625px;
			
			li {
				width: 48%;
				margin-right: 20px;
			}
			.explore-item {
				margin-right: 20px;
			}
			li:not(.explore-item) a {
				border-top: 1px solid var(--light-primary);
				border-bottom: none!important;
			}
			li:nth-last-child(2) a {
				border-bottom: 1px solid var(--light-primary)!important;
			}
			.menu-image {
				display: block;
				height: 100%;
				img {
					width: 500px;
					height: auto;
				}
				.image-menu-wrapper {
					float: right;
					top: 35px;
					position: absolute;
					right: 100px;
					height: 100%;
				}
			}
		}
	}
</style>