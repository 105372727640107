import { getEndpointUrl } from '../utils/api';

export const useAuth = defineStore('auth', {
  state: () => ({
    authenticated: false,
    loading: false,
		error: false,
  }),
  actions: {
    async authenticateUser(user, info) {
			const username = user?.username || '';
			const password = user?.password || '';

			const loginEndpoint = getEndpointUrl(
				'login',
				info.active_market,
				info.locale,
				'',
				info.market,
				false,
				username,
				password
			);

			this.loading = true;
      const { data, pending } = await useFetch(`${loginEndpoint}`, {
        method: 'POST',
      });

      if (data?.value?.token) {
				const token = useCookie('token');
				token.value = data.value.token;

				this.authenticated = true;
				this.loading = false;
      } else {
				this.authenticated = false;
				this.loading = false;
			}

			return data
    },
    
		logUserOut() {
      const token = useCookie('token');
      token.value = null;
      this.authenticated = false;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
}