<template><svg viewBox="12 15 220.7 84.4" fill="none" title="logo-lallemand" xmlns="http://www.w3.org/2000/svg">
  <g>
    <ellipse fill="#EF3E42" stroke="#EF3E42" cx="122.8" cy="57" rx="109.9" ry="40.4" />
  </g>
  <g>
    <path fill="#FFFFFF" d="M36.2,64.5h7.2l-0.8,6.2H29.4L33,42.4h6L36.2,64.5z" />
    <path fill="#FFFFFF" d="M51.9,65.8l-2.2,4.9h-6.4l12.6-28.3h6.6l5.1,28.3h-6.4l-0.9-4.9H51.9z M58.1,51.1L58.1,51.1l-3.7,9h5 L58.1,51.1z" />
    <path fill="#FFFFFF" d="M76.8,64.5H84l-0.8,6.2H69.9l3.7-28.3h6L76.8,64.5z"/>
    <path fill="#FFFFFF" d="M92.5,64.5h7.2l-0.8,6.2H85.6l3.7-28.3h6L92.5,64.5z" />
    <path fill="#FFFFFF" d="M110,48.6l-0.6,4.7h6.8l-0.8,6.2h-6.8l-0.6,4.9h7.2l-0.8,6.2H101l3.7-28.3h13.2l-0.8,6.2H110z" />
    <path fill="#FFFFFF" d="M124.1,42.4h5.9l2.7,15.1l7-15.1h6l-0.2,28.3h-6l0.4-16.3h-0.1l-7.7,16.3h-2.4l-3.2-16.3h-0.1l-4.1,16.3h-6 L124.1,42.4z" />
    <path fill="#FFFFFF" d="M154.8,65.8l-2.2,4.9h-6.4l12.6-28.3h6.6l5.1,28.3H164l-0.9-4.9H154.8z M161,51.1L161,51.1l-3.7,9h5 L161,51.1z" />
    <path fill="#FFFFFF" d="M176.2,42.4h6l8.8,17.3h0.1l2.3-17.3h6l-3.7,28.3h-6l-8.8-17.4h-0.1l-2.3,17.4h-6L176.2,42.4z" />
    <path fill="#FFFFFF" d="M202.6,42.4h8.6c6.5,0,11,6.2,10,14.2c-1,8-7.2,14.2-13.6,14.2h-8.6L202.6,42.4z M205.8,64.5h1.4 c3.6,0,7-2.4,7.7-7.9c0.7-5.1-1.7-7.9-5.6-7.9h-1.4L205.8,64.5z" />
  </g>
</svg></template>
