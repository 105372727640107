export const useToast = defineStore('toast', {
	// state
  state: () => ({
    toasts: [],
  }),

	// getters
  getters: {
    getAll: (state) => state.toasts,
  },

	// actions
  actions: {
    addToast(obj) {
			this.toasts = [
				...this.toasts,
				obj
			];
    },

		removeToast(id) {
			this.toasts = [...this.toasts].filter((t) => {
				t.id !== id
			});
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useToast, import.meta.hot))
}
