<template>
	<div
		v-if="showNotification"
		id="block-notifications" 
		class="block-notifications px-3 px-lg-100 py-3"
		:class="`bg-${getTheme(notificationData?.theme)}`" 
	>
		<div class="row">
			<div class="col-11">
				<div 
					:class="[
						`text-${getTextColor(notificationData?.theme)}`,
						`${notificationData?.cta_href ? 'mb-3' : ''}`
					]"
					v-html="notificationData?.text"
				>
				</div>
				
				<a
					v-if="notificationData?.cta_href"
					:href="notificationData?.cta_href"
					class="btn btn-sm btn-icon icon-end"
					:class="`btn-${getTheme(notificationData?.theme)}`" 
					:target="notificationData?.cta_target"
				>
					<span>
						{{ notificationData?.cta_label }}
					</span>
					<ArrowIcon />
				</a>
			</div>

			<div class="col-1 text-lg-end">
				<button 
					@click="closeNotifications()" 
					class="button-close"
					:class="[`text-${getTextColor(notificationData?.theme)}`]" 
					type="button" 
					:aria-label="$t('close')"
				>
					<CloseIcon />
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ArrowIcon from './icons/arrow.vue';
	import CloseIcon from './icons/close.vue';
	import { ref, onMounted } from 'vue';

	const config = useRuntimeConfig();
	const { ACTIVE_MARKET } = config.public || {};

	const route = useRoute();
	const showNotification = ref(false);
	
	// props
	const props = defineProps({
		data: Object
	});

	const notificationData = props?.data || {};

	// methods
	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	};

	const getCurrentMarketSlug = () => {
		//  @TODO - FOR SOME REASON, ON THE SERVER SIDE, THIS FUNCTION RETURN 'robot.txt' SOMETIME.
		// FIND WHY AND FIX IT (maybe  because it's in app.vue and not in <NuxtPage /> ?)
		if (getActiveMarketBoolean()) {
			const params = route?.params;
			const slugs = params?.market || 'europe';

			if (slugs.includes('robot') || slugs.includes('robots')) {
				return null
			}
	
			if (!slugs.includes('robot') && !slugs.includes('robots')) {
				return slugs;
			}
		}

		return '';
	};
	
	const closeNotifications = () => {
		showNotification.value = false;
    localStorage.setItem(`notificationClosed-${getCurrentMarketSlug()}`, 'true');
	};

	const getTheme = (theme = 'primary') => {
		if (theme === 'primary') {
			return 'primary';
		}

		if (theme === 'secondary') {
			return 'secondary'
		}

		if (theme === 'light-primary') {
			return 'light-primary'
		}

		if (theme === 'gray') {
			return 'corpo'
		}

		return theme;
	};

	const getTextColor = (theme = 'primary') => {
		if (theme === 'primary') {
			return 'white';
		}

		if (theme === 'secondary') {
			return 'white'
		}

		if (theme === 'light-primary') {
			return 'primary'
		}

		if (theme === 'gray') {
			return 'white'
		}

		return theme
	};
	
	// hooks
	onMounted(() => {
		const hasText = notificationData?.text;
	  const notificationClosed = localStorage.getItem(`notificationClosed-${getCurrentMarketSlug()}`);

		if (!notificationClosed && hasText) {
			showNotification.value = true;
		}
	});
</script>

<style lang="scss" scoped>
	.block-notifications{
		transition: all var(--ease-in) 1s;

	}
	button {
		> svg {
			path {
				stroke: currentColor;
			}
		}
	}
</style>