export default defineNuxtPlugin((nuxtApp) => {
	return {
		provide: {
			scrollToTop: () => {
				if (process.client) {
					window.scrollTo({ top: 0 })
				}
			},
    }
  }
})