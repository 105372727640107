export const useCookieModal = defineStore('cookieModal', {
  // state
	state: () => ({
    active: false,
		accepted: {},
  }),

	// getters
  getters: {
    isActive: (state) => state.active,
  },

	// actions
  actions: {
    setActive(newState) {
			if (newState === true || newState === false) {
				this.active = newState;
				return;
			}

			this.active = false;
    },

		setAccepted(newState) {
			if (newState === true || newState === false) {
				this.accepted = newState;
				return;
			}

			this.accepted = {};
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCookieModal, import.meta.hot))
}
