export const datalayer = ({ data = {} }) => {
	if (process.client) {
		const validData = () => {
			return Object.keys(data)?.length > 0;
		}

		if (validData()) {
			window?.dataLayer?.push(data);
		}
	}
};