import { default as _91_46_46_46slugs_93WCflgazbj5Meta } from "C:/xampp/htdocs/stack/pages/[market]/[...slugs].vue?macro=true";
import { default as index1w7feb2kLeMeta } from "C:/xampp/htdocs/stack/pages/[market]/index.vue?macro=true";
import { default as indexJiNnpmbj15Meta } from "C:/xampp/htdocs/stack/pages/index.vue?macro=true";
export default [
  {
    name: "market-slugs___en",
    path: "/en/:market()/:slugs(.*)*",
    component: () => import("C:/xampp/htdocs/stack/pages/[market]/[...slugs].vue")
  },
  {
    name: "market-slugs___fr",
    path: "/fr/:market()/:slugs(.*)*",
    component: () => import("C:/xampp/htdocs/stack/pages/[market]/[...slugs].vue")
  },
  {
    name: "market___en",
    path: "/en/:market()",
    component: () => import("C:/xampp/htdocs/stack/pages/[market]/index.vue")
  },
  {
    name: "market___fr",
    path: "/fr/:market()",
    component: () => import("C:/xampp/htdocs/stack/pages/[market]/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("C:/xampp/htdocs/stack/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("C:/xampp/htdocs/stack/pages/index.vue")
  }
]