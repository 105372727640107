import { storeToRefs } from 'pinia';

export default defineNuxtRouteMiddleware(async (to, from) => {
	const config = useRuntimeConfig();
	const ACTIVE_AUTH = config?.public?.ACTIVE_AUTH || "false";

	if (ACTIVE_AUTH === "true") {
		const authStore = useAuth();
		const { authenticated } = storeToRefs(authStore);
		const token = useCookie('token');
	
		if (token.value) {
			// check if value exists
			if (authenticated.value != true) {
				authenticated.value = true;
			}
		}
	}

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- defineNuxtRouteMiddleware ---');
	// console.log('to: ', to);
	// console.log('from: ', from);
})