<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" title="close" xmlns="http://www.w3.org/2000/svg">
		<path d="M2 2L22 22" :stroke="getColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M22 2L2 22" :stroke="getColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script setup>
	const props = defineProps({
		color: String
	})

	const getColor = props.color || '#323E48';
</script>
