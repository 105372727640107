export const usePage = defineStore('page', {
	// state
  state: () => ({
    page: {},
		pageRedirect: {},
  }),

	// getters
  getters: {
    getPage: (state) => state.page,
    getPageRedirect: (state) => state.pageRedirect,
  },

	// actions
  actions: {
		// page
    addPage(obj) {
			this.page = obj;
    },

		addKeyValue(key = null, obj = {}) {
			if (key) {
				this.page[key] = obj;
			}
    },

		removePage() {
			this.page = {};
    },

		removeKey(key = null) {
			if (key) {
				delete this.page[key];
			}
    },

		// pageRedirect
		setPageRedirect(value) {
			if (value !== this.pageRedirect) {
				this.pageRedirect = value;
			}
		},
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePage, import.meta.hot))
}
